import React from 'react';

import { AppStoreContext } from '../../../../stores/AppStore';
import { fireTag } from '../../../../utils/Analytics';
import getGlobalBlock from '../../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../../utils/Analytics/blocks/getPageBlock';
import getOfferListBlock from '../../../../utils/Analytics/blocks/getOfferListBlock';
import getLinkBlock from '../../../../utils/Analytics/blocks/getLinkBlock';
import ModalClose from '../../../../components/Modal/ModalClose';
import Container from '../../../../components/Container';
import FinanceType from '../../../../components/FinanceType';
import VehicleCategory from '../../../../components/VehicleCategory';
import getListItem from '../../../../utils/Analytics/blocks/getListItem';

import styles from './BudgetOptionsHeader.module.scss';

interface FilterMenuProps {
  onClose: () => void;
}

const FilterMenu = (props: FilterMenuProps) => {

  const contentSection = 'wfmb_filter_menu';
  const { onClose } = props;
  const {
    AppStore: {
      getVehicles,
  }} = React.useContext(AppStoreContext);

  React.useEffect(() => {
    fireTag(
      'ready',
      {
        metrics: 'aa-pageload',
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getOfferListBlock({layout: 'list'})
      }
    ); 
  }, [contentSection]);

  const handleClose = async () => {
    fireTag(
      'aa-link',
      {
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getLinkBlock({
          typeTitle: 'Close',
          trigger: 'aa-link',
          buttonAction: 'Close',
          type: 'Button'
        }),
        ...getOfferListBlock({layout: 'list'}),
      }
    );
    await getVehicles();
    fireTag(
      'ready',
      {
        metrics: 'aa-pageload',
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getOfferListBlock({layout: 'list'})
      }
    ); 
    onClose();
  };

  return (
    <div className={styles.filterModal}>
      <ModalClose close={() => handleClose()} />
      <Container className={styles.catContainer}>
        <FinanceType
          onClick={text => {
            fireTag('aa-link', {
              ...getGlobalBlock(),
              ...getPageBlock(contentSection),
              ...getLinkBlock({
                text: text, 
                typeTitle: text,
                trigger: 'aa-link',
                buttonAction: 'Toggle',
                type: 'Button'
              }),
              ...getOfferListBlock({layout: 'list'}),
            });
          }}
        />
        <VehicleCategory
          onClick={(text, index) => {
            fireTag('aa-link',
              {
                ...getLinkBlock({
                  url: '/',
                  href: '/',
                  text: text, 
                  typeTitle: text,
                  trigger: 'aa-link',
                  buttonAction: 'Select',
                  type: 'Button'
                }),
                ...getPageBlock(contentSection),
                ...getListItem(index),
                ...getOfferListBlock({layout: 'list'}),
              } 
            );
          }}
        />
      </Container>
    </div>
  );
};

export default FilterMenu;
