import { VehiclesResponse, VehiclesResponseItem } from '../types/vehiclesResponse.models';

export const findVehicleAndTrim = (
  vehicles: VehiclesResponse[],
  series?: string,
  model?: string,
  financeType?: string,
) => {
  let vehicleFound = false;
  let selectedSeries = {} as VehiclesResponse;
  let selectedTrim = {} as VehiclesResponseItem;
  vehicles.forEach(vehicle => {
    if (!vehicleFound) {
      vehicle.inBudget.forEach(budget => {
        if (!vehicleFound &&
            budget.series === series &&
            budget.model === model &&
            budget.purchaseType === financeType) {
          selectedSeries = vehicle;
          selectedTrim = budget;
          vehicleFound = true;
        }
      })
    }
  });

  return {
    vehicleFound,
    selectedSeries,
    selectedTrim
  }
};
