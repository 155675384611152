import Resources_en from './resources/ResourcesEn.json';
import Resources_es from './resources/ResourcesEs.json';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AppStore from './stores/AppStore';

// the translations
// (tip move them in a JSON file and import them)
const pathNames = window.location.pathname.split('/');
let foundLang = pathNames.find(name => name === 'en' || name === 'es' || name === 'espanol');
foundLang = foundLang && foundLang === 'espanol' ? 'es' : foundLang;
if(!foundLang){
  const langParam = window.location.search.trim().match(/lang=en/g) || 
        window.location.search.trim().match(/lang=es/g) || 
        window.location.search.trim().match(/lang=espanol/g) || [];
  foundLang = (langParam.length && langParam[0]) === 'lang=es' || (langParam.length && langParam[0]) === 'lang=espanol'? 'es' : 'en';
}
AppStore.setLanguage(foundLang);
const resources = AppStore.language === 'es' ? Resources_es : Resources_en;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: AppStore.language,

    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
