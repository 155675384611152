import { Dealer } from "../../../types/dealer.models";

interface DealerBlock {
  brand: string;
  code: string;
  name: string;
  zipCode: string;
  city: string;
  state: string;
  metro?: string;
  hasTxm?: boolean;
  hasEspanol?: boolean;
  hasTireCenter?: boolean;
  hasScheduleService?: boolean;
  hasServiceOnly?: boolean;
  tdaCode: string;
}

const getDealerBlock = (dealer: DealerBlock) => ({
  dealer_brand: dealer.brand,
  dealer_code: dealer.code,
  dealer_name: dealer.name,
  dealer_zipcode: dealer.zipCode,
  dealer_city: dealer.city,
  dealer_state: dealer.state,
  dealer_metro: dealer.metro,
  dealer_has_txm: dealer.hasTxm ? 'Yes' : 'No',
  dealer_has_espanol: dealer.hasEspanol ? 'Yes' : 'No',
  'dealer_has_tire-center': dealer.hasTireCenter ? 'Yes' : 'No',
  dealer_has_schedule_service: dealer.hasScheduleService ? 'Yes' : 'No',
  dealer_has_service_only: dealer.hasServiceOnly ? 'Yes' : 'No',
  tda_code: dealer.tdaCode
});

export const getDealerBlockFromResponse = (dealer: Dealer) => {
  const {
    name,
    code,
    tdaCode,
    contact: {
      city,
      state,
      zip,
    }
  } = dealer; 

  return getDealerBlock({
    brand: 'toyota',
    code,
    name,
    zipCode: zip,
    city,
    state,
    tdaCode
  });
};

export default getDealerBlock;
