import React from 'react';

interface NumberFormatProps extends React.HTMLAttributes<HTMLDivElement> {
  dollar?: boolean;
  value?: number;
}

const NumberFormat = (props: NumberFormatProps) => {

  const { dollar, value, className } = props;
  const formattedValue = value ? value.toLocaleString(undefined, {maximumFractionDigits:0}) : ''; 

  return (
    <>
      { dollar && <sup className={className}>$</sup> }
      {formattedValue}
    </>
  );
};

export default NumberFormat;
