import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { AppStoreContext } from '../../../stores/AppStore';
import { PostMessageStoreContext } from '../../../stores/PostMessageStore';
import { fireTag } from '../../../utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getOfferListBlock from '../../../utils/Analytics/blocks/getOfferListBlock';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import Header from '../../../components/Header';
import Tabs from '../../../components/Tabs';
import FadeIn from '../../../components/FadeIn';
import Container from '../../../components/Container';
import FinanceType from '../../../components/FinanceType';
import Button from '../../../components/Button';

import styles from './FinanceType.module.scss';

const Finance = observer(() => {

  const {
    AppStore: {
      financeTypes,
      nextMobileState,
  }} = React.useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight }
  } = React.useContext(PostMessageStoreContext);

  const { t } = useTranslation();
  const contentSection = 'wfmb_step1_budget_options';

  React.useEffect(() => {
    sendHeight();
    fireTag(
      'aa-component-view',
      {
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getOfferListBlock(),
      }
    );
  }, [sendHeight]);

  const handleNextButton = (text: string) => {
    fireTag('aa-link', {
      ...getGlobalBlock(),
      ...getPageBlock(contentSection),
      ...getLinkBlock({
        url: '/',
        href: '/',
        text, 
        typeTitle: text,
        trigger: 'aa-link',
        type: 'Button',
      }),
      ...getOfferListBlock(),
    });
    nextMobileState();
  };

  return (
    <>
      <Header hideBackBtn={true} />
      <Tabs />
      <FadeIn>
        <Container>
          <FinanceType
            onClick={text => {
              fireTag('aa-link', {
                ...getGlobalBlock(),
                ...getPageBlock(contentSection),
                ...getLinkBlock({
                  text: text, 
                  typeTitle: text,
                  trigger: 'aa-link',
                  buttonAction: 'Toggle',
                  type: 'Button'
                }),
                ...getOfferListBlock(),
              });
            }}
          />
          <div className={styles.ctaContainer}>
            <Button
              className={styles.nextCta}
              variant='redBackground'
              fullWidth
              onClick={() => handleNextButton(t('financeType.next'))}
              disabled={!financeTypes.length}
            >
              {t('financeType.next')}
            </Button>
          </div>
        </Container>
      </FadeIn>
    </>
  );
});

export default Finance;
