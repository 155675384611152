import React, { useState } from 'react';
import styles from '../BudgetDesktop.module.scss';
import { useTranslation } from 'react-i18next';
import DisclaimersModal from '../../../components/DisclaimersModal';
import Disclaimers from '../../../components/Disclaimers';
import AppStore from '../../../stores/AppStore';
import cx from 'classnames';
import { fireTag } from '../../../utils/Analytics';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getOfferListBlock from '../../../utils/Analytics/blocks/getOfferListBlock';

const Header = () => {

  const { t } = useTranslation();
  const [ openDisclaimers, setOpenDisclaimers ] = useState(false);


  const taggingBlocks = () => ({
    ...getGlobalBlock(),
    ...getPageBlock('wfmb_filter_menu'),
    ...getOfferListBlock({layout: 'grid', includeAlmost: true}),
  });

  const handleOpenDisclaimer = () => {
    fireTag("aa-link", {
      ...taggingBlocks(),
      ...getLinkBlock({
        text: "1",
        typeTitle: "1",
        trigger: "aa-link",
        buttonAction: "Open Modal",
        type: "Button",
      }),
    });
    setOpenDisclaimers(true);
  };

  

  return (
    <header className={styles.header}>
      <div className={styles.head}>{t('budgetInterstitial.heading')}</div>
      <p>{t('budgetInterstitial.instructions')}</p>
      <sup
        className={cx(styles.disclaimer,{[styles.es]: AppStore.language === 'es'})}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenDisclaimer();
        }}
      > 1
      </sup>
        <DisclaimersModal open={openDisclaimers} onClose={() => setOpenDisclaimers(false)}>
          <Disclaimers onClose={() => setOpenDisclaimers(false)}>
            {t('disclaimers.sliderDisclaimer')}
          </Disclaimers>
        </DisclaimersModal>
    </header>
  );
};

export default Header;
