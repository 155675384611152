import React from 'react';
import styles from './error.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../src/components/Button';
import Header from '../../../src/components/Header';
import errorCarImg from '../../images/errorcars.png';

const Error = () => {

  const { t } = useTranslation();

  const handleOnClick = () => {
    window.location.reload();
  };

  return (
    <section className={styles.error}>
      <div className={styles.desktop}>
        <h1 className={styles.title}>{t('error.title')}</h1>
        <section className={styles.container}>
          <section className={styles.wrapper}>
            <div className={styles.sectionLeft}>
              <div>
                <p className={styles.message}>{t('error.message')}</p>
                <div className={styles.instructionContainer}>
                  <p className={styles.instruction}>{t('error.instruction')}</p>
                </div>
                <Button variant='redBackground' onClick={() => handleOnClick()}>{t('error.cta')}</Button>
              </div>
            </div>
            <div className={styles.sectionRight}>
              <img className={styles.image} src={errorCarImg} alt='error cars' />
            </div>
          </section>
        </section>
      </div>
      <div className={styles.mobile}>
        <Header />
        <section className={styles.mobileWrapper}>
          <p className={styles.message}>{t('error.message')}</p>
          <div className={styles.instructionContainer}>
            <p className={styles.instruction}>{t('error.instruction')}</p>
          </div>  
          <img className={styles.image} src={errorCarImg} alt='error cars' />
          <Button className={styles.cta} variant='redBackground' onClick={() => handleOnClick()}>{t('error.cta')}</Button>
        </section>
      </div>
    </section>
  );
};

export default Error;
