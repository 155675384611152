import { AxiosResponse } from "axios";
import vehicleCategories from '../components/VehicleCategory/vehicleCategories.json';
import { WFMBVehiclesResponse } from '../webservices/models/wfmb.models';
import { EFCMSResponse, EFCMSSeries } from '../types/efcMs.models';
import { VehiclesResponse, VehiclesResponseItem } from '../types/vehiclesResponse.models';
import { Dealer } from "../types/dealer.models";

export const disZipXForm = (disData: any) => {
  const specifiedOrganization = disData.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail[0].dealerParty.specifiedOrganization;
  const state = specifiedOrganization.postalAddress.stateOrProvinceCountrySubDivisionID.value as string;
  const regionCode = `${specifiedOrganization.divisionCode.value}0`; //SLAP A ZERO AT THE END OF THE RETURNED 2 CHAR CODE SO 11 becomes 110.
  const tdaCode = disData.showDealerLocatorDataArea.dealerMetaData[0].dealerMetaDataDetail[0].organization.reduce((tda:any, orgItem:any) => {
    if (orgItem.code.value === 'Association') {
      return orgItem.party[0].specifiedOrganization.companyCode.value;
    }
    return tda ;
  }, undefined) as string;
  const pricingArea = disData.showDealerLocatorDataArea.dealerMetaData[0].dealerMetaDataDetail[0].program.reduce((pA:any, proItem:any) => {
    if (proItem.code.value === 'PricingArea') {
      return proItem.description.value;
    }
    return pA; // PricingArea
  }, undefined) as string;

  return {
    state,
    regionCode,
    tdaCode,
    pricingArea
  }
};

export const disDealerXForm = (
  pmaData: any,
  proximityData: any,
  tdaCode: string
) => {

  const pma = pmaData.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail;
  const proximity = proximityData.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail;
  const allDealers = [] as Dealer[];

  // assign pma flag
  for (const item of pma) {
    item.isPma = true;
  }

  // push proximity items into pma
  if (pma) {
    const proximityToAdd = proximity.filter((item:any) => {
      const foundItem = pma.filter((o:any) => { 
        return o.dealerParty.partyID.value === item.dealerParty.partyID.value;
      });  
      return !foundItem;
    });
    pma.push(...proximityToAdd);
  }

  for(const item of pma) {
    // Create simpler objects for display purposes.
    const distance = item.proximityMeasureGroup.proximityMeasure.value;
    item.tdaCode = tdaCode;
    // Service should only be returning dealers < 50m
    if(distance < 50) {
      const obj = new Dealer(item);
      allDealers.push(obj);
    }
  }

  return allDealers;
};

export const vehiclesXForm = (apiResponse: AxiosResponse<WFMBVehiclesResponse[]>, efcData: EFCMSResponse, currentBudgetValueMax: number) => {
 
  const copyEFCDataToOffer = (
    vehicleResponse: VehiclesResponse,
    budgetItems: VehiclesResponseItem[],
    efcSeries: EFCMSSeries, 
  ) => {
    budgetItems.forEach(budgetItem => {
      budgetItem.series = vehicleResponse.series;
      budgetItem.seriesName = efcSeries.name || '';
      efcSeries.trims.forEach(trim => {
        if (trim.code === budgetItem.model) {
          budgetItem.carJellyImage = vehicleResponse.image;
          budgetItem.grade = trim.attributes.filter(item => item.type === 'grade')[0].title;

          // variables added for analytics
          budgetItem.category = vehicleCategories
            .filter(category => {
              return category.seriesList.indexOf(budgetItem.series) !== -1
            }) 
            .map(category => category.category)[0]
        }
      });
    })  
  };

  const vehiclesData = apiResponse.data.map(item => new VehiclesResponse(item));
  vehiclesData.forEach((item: VehiclesResponse) => {
    efcData.years_list.forEach(efcYear => {
      efcYear.series_list.forEach(efcSeries => {
        if (efcSeries.series === item.series) {
          item.image = `${process.env.REACT_APP_CAR_JELLY_DOMAIN}/assets/img/vehicles/${item.series.toLowerCase()}/default-all-vehicles.png`;
          item.seriesName = efcSeries.name || '';
          copyEFCDataToOffer(item, item.inBudget, efcSeries);
          copyEFCDataToOffer(item, item.almostInBudget, efcSeries);
        }
      })
    })

    // flag almostInBudget that are over $50 or less
    item.almostInBudget.forEach(budgetItem => {
      budgetItem.budgetDifference = (budgetItem.payment - currentBudgetValueMax);
      budgetItem.underLimit = budgetItem.budgetDifference > 0 && budgetItem.budgetDifference <= 50;
    })
  });
  return vehiclesData;
}

export const getMinimumPayment = (vehicleList: VehiclesResponse[]) => {
  let newMin = 0;
  if (typeof vehicleList !== 'string') {
    vehicleList.forEach(vehicle => {
      vehicle.inBudget.forEach(budget => {
        if (newMin === 0 || budget.payment < newMin) {
          newMin = budget.payment;
        }
      });
    });
  }
  // round down to nearest 5
  return newMin - (newMin%5);
}
