import React from 'react';
import styles from './FinanceType.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Title from '../../../src/components/Title';
import FinanceTypeItem from '../../../src/components/FinanceType/FinanceTypeItem';
import { AppStoreContext } from '../../../src/stores/AppStore';

interface FinanceTypeProps {
  onClick: (text: string) => void;
}

const FinanceType = observer((props: FinanceTypeProps) => {

  const { onClick } = props;
  const {
    AppStore: {
      setFinanceType,
      financeTypes,
    }
  } = React.useContext(AppStoreContext); 
  const { t } = useTranslation();
  const [ buySelected, setBuySelected ] = React.useState(financeTypes.indexOf('buy') !== -1);
  const [ leaseSelected, setLeaseSelected ] = React.useState(financeTypes.indexOf('lease') !== -1);

  return (
    <>
      <Title className={styles.typeHeading}>{t('financeType.heading')}</Title>

      {/* Finance Type Selection */}
      <section className={styles.tiles}>

        <FinanceTypeItem
          label={t(`financeType.buy`)}
          selected={buySelected}
          onClick={() => {
            setBuySelected(!buySelected);
            setFinanceType(!buySelected, leaseSelected);
            onClick(t(`financeType.buy`));
          }}
        />
        <FinanceTypeItem
          label={t(`financeType.lease`)}
          selected={leaseSelected}
          onClick={() => {
            setLeaseSelected(!leaseSelected);
            setFinanceType(buySelected, !leaseSelected);
            onClick(t(`financeType.lease`));
          }}
        />

      </section>
    </>
  );
});

export default FinanceType;
