import React, { useState } from 'react';
import Input from './Input';
import styles from '../ContactDealer.module.scss';
import { useTranslation } from 'react-i18next';

interface InputFormProps {
  onInteraction: (field: string) => void;
  register: any;
}

const InputForm = ({
  onInteraction,
  register,
}: InputFormProps) => {
  const { t } = useTranslation();
  
  const [contactPref, setContactPref] = useState<'phone' | 'email'>('email');
  const [ openComments, setOpenComments ] = useState(false);
  
  const commentsOpen = () => setOpenComments(true);

  return (
    <>
      {/* First/Last name */}
      <label htmlFor='contact' className={styles.label}>
        {t('raq.contact')}
      </label>

      <Input
        id='firstName'
        name='firstname'
        onClick={() => onInteraction('firstName')}
        placeholder='First Name (required)'
        ref={register}
        required
      />

      <Input
        id='lastName'
        name='lastname'
        onClick={() => onInteraction('lastName')}
        placeholder='Last Name (required)'
        ref={register}
        required
      />

      {/* Contact Preference */}
      <label htmlFor='preference' className={styles.label}>
        {t('raq.preference')}
      </label>

      <span className={styles.preferenceContainer}>
        <span>
          <input
            checked={contactPref === 'email'}
            className={styles.radio}
            name='preference'
            onChange={() => setContactPref('email')}
            ref={register}
            type='radio'
            value='email'
          />
          <label htmlFor='preference' className={styles.contactOption}>
            Email
          </label>
        </span>
        <span>
          <input
            checked={contactPref === 'phone'}
            className={styles.radio}
            name='preference'
            onChange={() => setContactPref('phone')}
            ref={register}
            type='radio'
            value='phone'
          />
          <label htmlFor='preference' className={styles.contactOption}>
            Phone
          </label>
        </span>

      </span>

      {/* Contact Preference Fields */}
      <Input
        defaultValue=''
        name='email'
        onClick={() => onInteraction('email')}
        pattern="(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}"
        placeholder={contactPref === 'email' ? 'Email (required)' : 'Email'}
        ref={register}
        required={contactPref === 'email'}
        type='email'
      />

      <Input
        defaultValue=''
        name='phone'
        onClick={() => onInteraction('phone')}
        pattern="(\([0-9]{3}\)|[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4}"
        placeholder={contactPref === 'phone' ? 'Phone (required)' : 'Phone'}
        ref={register}
        required={contactPref === 'phone'}
        type='tel'
      />

    {/* (\([0-9]{3}\)|[0-9]{3}-|[0-9]{3})([0-9]{3}-|[0-9]{3})[0-9]{4} */}

      <div className={styles.commentsWrapper}>
        <span onClick={commentsOpen}>+</span>
        <label htmlFor='comments' onClick={commentsOpen}>
          {t('raq.comments')}
        </label>
        {openComments && (
          <textarea
            maxLength={250}
            name='comments'
            placeholder='Comments'
            ref={register}
          />
        )}
      </div>
    </>
  );
};

export default InputForm;
