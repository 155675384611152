import { slpData } from "types/requestAQuote.models";
import { API } from "./api";

export const submitSLPLead = (json: slpData) => {
  const url = `${process.env.REACT_APP_SLP_API}/leads`;
  return API({
    url: url,
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_SLP_API_KEY,
      'Content-Type': 'application/json'
    },
    data: json
  });
};
