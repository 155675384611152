type LinkBlockType = 'Button' | 'Link' | 'Gesture' | 'Checkbox' | 'free text';

type LinkBlock = {
  url?: string;
  href?: string;
  text?: string;
  typeTitle?: string;
  trigger: 'aa-link' | 'km-link';
  buttonAction?: string;
  action?: string;
  typeClick?: string;
  type?: LinkBlockType
};

const getLinkBlock = (link: LinkBlock) => ({
  link_url: link.url,
  link_href: link.href,
  link_text: link.text || 'Link',
  link_type_title: link.typeTitle,
  link_trigger: link.trigger,
  link_button_action: link.buttonAction,
  link_type: link.type,
  link_action: link.action,
  link_type_click: link.typeClick,
});

export default getLinkBlock;
