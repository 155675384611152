import React from 'react';
import Modal, { ModalProps } from '../Modal';
import styles from './DisclaimersModal.module.scss';

const DisclaimersModal = (props: ModalProps) => {
  return (
    <Modal className={styles.disclaimersModal} {...props} />
  );
};

export default DisclaimersModal;
