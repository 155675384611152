import React from 'react';
import cx from 'classnames';
import styles from './Loading.module.scss';

interface LoadingProps {
  useParentHeight?: boolean;
}

const Loading = (props: LoadingProps) => {

  const { useParentHeight } = props;

  return (
    <section className={cx({
      [styles.loading]: true,
      [styles.noParent]: !useParentHeight
    })}>
      <div className={styles.icon} />
    </section>
  );
};

export default Loading;
