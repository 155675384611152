import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next'
import { VehiclesResponseItem } from '../../../src/types/vehiclesResponse.models';
import NumberFormat from '../../../src/components/NumberFormat';
import useMobileDetect from '../../hooks/useMobileDetect';
import styles from './FinanceInfoOfferDetails.module.scss';

interface FinanceInfoOfferDetailsProps {
  selectedTrim: VehiclesResponseItem;
}

const FinanceInfoOfferDetails = ({
  selectedTrim,
}: FinanceInfoOfferDetailsProps) => {

  const { t } = useTranslation();
  const { isMobile } = useMobileDetect();

  return (
    <section className={styles.financeSection}>
      <div className={styles.financeContainer}>
          <>
            <div className={styles.financeInfo}>
              {
                !isMobile && 
                <>
                  <div>
                    <span className={styles.financePayment}>${Math.round(selectedTrim.payment)}</span>
                    <span className={styles.financePaymentTerm}>{t('vehicleDetails.perMonthDesktop')}</span>
                  </div>
                  <div className={styles.otherDetails}>
                  <div>
                    <span className={styles.value}>{selectedTrim.term}</span>
                    {` ${t('vehicleDetails.mos')}`}
                  </div>
                  { selectedTrim.purchaseType === 'lease' &&
                    <div>
                      <span className={styles.value}>
                        $<NumberFormat value={selectedTrim.dueAtSigning} />
                      </span>
                      {` ${t('common.dueAtSigning')}`}
                    </div>
                  }
                  { selectedTrim.purchaseType === 'buy' &&
                    <div>
                      <span className={styles.value}>{selectedTrim.rate}%</span>
                      {` ${t('selectTrim.apr')}`}
                    </div>
                  }
                </div>
              </>
              }
              {
                isMobile &&
                <>
                  <div className={styles.paymentWrapper}>
                    <div className={cx(styles.financePayment, styles.payment)}>${Math.round(selectedTrim.payment)}</div>
                    <div>{`${t('selectTrim.perMonth')}`} {selectedTrim.term} {` ${t('selectTrim.label')}`}</div>
                  </div>
                  { selectedTrim.purchaseType === 'lease' &&
                    <div>
                      <div className={styles.financePayment}>
                        $<NumberFormat value={selectedTrim.dueAtSigning} />
                      </div>
                      <div>
                        {` ${t('common.dueAtSigning')}`}
                      </div>
                    </div>
                  }
                  { selectedTrim.purchaseType === 'buy' &&
                    <div>
                      <div className={styles.financePayment}>{selectedTrim.rate}%</div>
                      <div className={styles.aprLabel}>{` ${t('selectTrim.apr')}`}</div>
                    </div>
                  }
                </>
              }
            </div>
          </>
      </div>
    </section>
  );
};

export default FinanceInfoOfferDetails;
