import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './Tabs.module.scss';
import { observer } from 'mobx-react';
import { AppStoreContext } from '../../stores/AppStore';

const Tabs = () => {
 
  const {
    AppStore
  } = useContext(AppStoreContext);
  
  return (
    <div className={styles.tabs}>
      {/* Finance Type */}
      <div>
        <Link 
          className={
            cx([
              styles.tab1, 
              {
                [styles.currentTab]: AppStore.currentState === 'finance'
              }])
            } 
          to={'/'}
        />
        {
          (AppStore.currentState === 'vehicleCategory' || AppStore.currentState === 'budget' ||
            AppStore.currentState === 'budgetOptions') && 
          <span className={styles.processStatus1}></span>
        }
        <label 
          htmlFor="step1" 
          className={styles.tabLabel1}>
            STEP 1
        </label>
      </div>
      
      {/* Vehicle Category */}
      <div>
        <Link 
          className={
            cx([
              styles.tab2, 
              {
                [styles.disableTab]: AppStore.currentState === 'finance',
                [styles.currentTab]: AppStore.currentState === 'vehicleCategory'
              }
            ])
          } 
          to={'/vehicleCategory'} 
        />
        {
          (AppStore.currentState === 'budget' || AppStore.currentState === 'budgetOptions') && 
          <span className={styles.processStatus2}></span>
        }
        <label 
          htmlFor="step2" 
          className={
            cx(styles.tabLabel2, {[styles.disableLabel]: AppStore.currentState === 'finance'})}>
            STEP 2
        </label>
      </div>
      
      {/* Budget */} 
      <div>
        <Link 
          className={
            cx([
              styles.tab3, 
              {
                [styles.disableTab]: AppStore.currentState === 'finance' || AppStore.currentState === 'vehicleCategory',
                [styles.currentTab]: AppStore.currentState === 'budget'
              }
            ])
          } 
          to={'/budget'}
        />
        {
          (AppStore.currentState === 'budgetOptions') && 
          <span className={styles.processStatus3}></span>
        }
        <label 
          htmlFor="step3" 
          className={
            cx(styles.tabLabel3, 
            {
              [styles.disableLabel]: AppStore.currentState === 'finance' || AppStore.currentState === 'vehicleCategory'
            })
          }
        >
            STEP 3
        </label>
      </div>
    </div>
    );

};

export default observer(Tabs);
