import React, { HTMLAttributes } from 'react';
import styles from './VehicleDetails.module.scss';

const VehicleDetailsDisclaimers = (props: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={styles.disclaimers} {...props}/>
  );
};

export default VehicleDetailsDisclaimers;
