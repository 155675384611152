const tdaCodeMap = {
  'BOS10': 'newengland',
  'CAT10': 'centralatlantic',
  'CHI10': 'midwest',
  'CIN10': 'mi-oh-ky-tn',
  'CON10': 'connecticut',
  'DVR10': 'denver',
  'GNY10': 'greaterny',
  'GOC10': 'goldcoast',
  'GST10': 'gst',
  'INW10': 'inlandnorthwest',
  'KAC10': 'central',
  'NCA10': 'norcal',
  'PHX10': 'phoenix',
  'PTL10': 'pacificnorthwest',
  'SET10': 'southeasttoyota',
  'SND10': 'sandiego',
  'SOC10': 'socal',
  'TRE10': 'tristateeast',
  'TRI10': 'tristate',
  'UST10': 'upstateny',
  'WWN10': 'westernwashington',
} as {[code: string]: string};

export const getTdaByCode = (code: string) => {
  return tdaCodeMap[code];
};
