/**
 * Landing Page for WFMB
 * 
 * Mobile has a whole work flow for picking out finance types and vehicles
 * Desktop is all in one
 */
import React from 'react';
import { observer } from 'mobx-react';
import useMobileDetect from '../../../src/hooks/useMobileDetect';
import { AppStoreContext, MobileState } from '../../../src/stores/AppStore';

import BudgetDesktop from '../BudgetDesktop';
import Finance from '../Mobile/Finance';
import VehicleCategories from '../Mobile/VehicleCategories';
import BudgetOptions from '../Mobile/BudgetOptions';
import Budget from '../Mobile/Budget';
import SelectTrim from '../Mobile/SelectTrim';

const BudgetInterstitial = observer(() => {

  const {
    AppStore: {
      currentState,
    }
  } = React.useContext(AppStoreContext);

  const { isMobile } = useMobileDetect();
  const MobileSwitch = (currentState: MobileState) => {
    switch(currentState) {
      case 'finance': return <Finance />;
      case 'vehicleCategory': return <VehicleCategories />
      case 'budget': return <Budget />
      case 'budgetOptions': {
        return <BudgetOptions />
      }
      case 'selectTrim': return <SelectTrim />
      default: return (null);
    }
  }

  return (
    <>
      { !isMobile && <BudgetDesktop /> }
      { isMobile && MobileSwitch(currentState) }
    </>
  );
});

export default BudgetInterstitial;
