import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehiclesResponseItem } from '../../types/vehiclesResponse.models';
import { MarketingOffer } from '../../types/marketingOffer.models';
import NumberFormat from '../../components/NumberFormat';
import Accordion from '../../components/Accordion';
import AccordionItem from '../../components/Accordion/AccordionItem';
import VehicleDetailsBullets from './VehicleDetailsBullets';
import VehicleDetailsDisclaimers from './VehicleDetailsDisclaimers';

interface VehicleDetailsAccordionProps {
  selectedTrim: VehiclesResponseItem;
  marketingOffers: MarketingOffer[];
}

const VehicleDetailsAccordion = ({
  selectedTrim,
  marketingOffers,
}: VehicleDetailsAccordionProps) => {

  const { rate } = selectedTrim;
  const term = selectedTrim.offer ? selectedTrim.offer.term : 0;
  const { t } = useTranslation();
  const tExpandText = t('vehicleDetails.expandOfferDetails');
  const tCollapseText = t('vehicleDetails.collapseOfferDetails');
  const [ toggleText, setToggleText ] = useState(tCollapseText);

  const getHeaderText = (marketingOffer: MarketingOffer) => {
    switch(marketingOffer.offerType) {
      case 'Lease': {
        let monthlyPayment = '';
        if (marketingOffer.lease) {
          marketingOffer.lease.tiers.tier.forEach(tier => {
            tier.term.forEach(term => {
              monthlyPayment = term.monthlyPayment;   
            });   
          });
        }
        return t('vehicleDetails.leaseDescription', {payment: monthlyPayment, term: term})
      }
      case 'APR': return t('vehicleDetails.aprDescription', {rate: rate, term: term});
      case 'Multiple_Vehicle': return <>
        $<NumberFormat value={Number(marketingOffer.multivehicle?.cashAmount)} />
        {` ${marketingOffer.offerCard?.primaryOfferCardLabel}`}
      </>
      default: return <>
        $<NumberFormat value={Number(marketingOffer.cash?.cashAmount)} />
        {` ${marketingOffer.offerCard?.primaryOfferCardLabel}`}
      </>;
    }
  };

  return (
    <Accordion>
      { marketingOffers.map((marketingOffer, index) =>
        <AccordionItem
          defaultExpanded
          key={index}
          header={getHeaderText(marketingOffer)} 
          toggleText={toggleText}
          onChecked={value => value ? setToggleText(tCollapseText) : setToggleText(tExpandText)}
          section={
            <>
              <VehicleDetailsBullets marketingOffer={marketingOffer} />
              {marketingOffer.disclaimers.disclaimer.map((item,index) =>
                <VehicleDetailsDisclaimers key={index}>{item}</VehicleDetailsDisclaimers>  
              )}
            </>
          }
        />
      )}
    </Accordion>
  );
};

export default VehicleDetailsAccordion;
