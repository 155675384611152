import React from 'react';
import styles from './Slider.module.scss';
import Slider, { createSliderWithTooltip, Handle } from 'rc-slider';
import "rc-slider/assets/index.css";
import { observer } from 'mobx-react';
import { AppStoreContext } from '../../../src/stores/AppStore';
import CustomHandle from './CustomHandle';

interface BudgetSliderProps {
  min: number,
  max: number,
  hideToolTip: boolean,
  onChange?: (value: number) => void; 
  onAfterChange?: (value: number) => void; 
}

const BudgetSelector = createSliderWithTooltip(Slider);
const BudgetSlider = observer((props: BudgetSliderProps) => {

  const {
    AppStore: {
      setBudgetValueMax,
      currentBudgetValueMax,
    }
  } = React.useContext(AppStoreContext);

  const {
    min,
    max,
    hideToolTip,
    onChange = () => {},
    onAfterChange = () => {},
  } = props;

  const handleOnAfterChange = async (value: number) => {
    onAfterChange(value);
  };

  return (
    <div className={styles.budgetSlider}>
      {
        hideToolTip ? (
          <Slider
            min={min}
            max={max}
            value={currentBudgetValueMax}
            handle={({dragging, ...handleProps}) =>
              <Handle dragging={dragging.toString()} {...handleProps}>
                <CustomHandle />
              </Handle>
            }
            onChange={ (value) => {
              onChange(value);
              setBudgetValueMax(value)
            }}
            onAfterChange={(value) => { handleOnAfterChange(value) }}
          />
        ) : (
          <BudgetSelector
            min={min}
            max={max}
            value={currentBudgetValueMax}
            onChange={value => {
              onChange(value);
              setBudgetValueMax(value)
            }}
            onAfterChange={(value) => { handleOnAfterChange(value) }}
            tipFormatter={() => <span className="tooltip">${currentBudgetValueMax}</span>}
          />
        )
      }
      
    </div>
  );
});

export default BudgetSlider;
