import { EFCMSSeriesResponse } from "types/efcMs.models";
import { VehiclesResponse, VehiclesResponseItem } from "../types/vehiclesResponse.models";

const getSeriesNameByYear = (seriesData: EFCMSSeriesResponse | undefined, vehicle: VehiclesResponseItem) => {

  if (!vehicle) {
    return;
  }
  
  const seriesListByYear = seriesData?.series_data.find((year: any) => year.year === vehicle.year);
    const titleForSeries = seriesListByYear?.series_list.find((serie: any) => serie.code === vehicle.series)?.title;
  if(titleForSeries) {
    vehicle.seriesName = titleForSeries
  }
}

const getBudgetArrays = (vehiclesList: VehiclesResponse[], seriesData?: EFCMSSeriesResponse) => {

  let inBudgetOffers = [] as VehiclesResponseItem[];
  let almostInBudgetOffers = [] as VehiclesResponseItem[];
  vehiclesList.forEach(vehicle => {
    if (vehicle.inBudget.length) {
      vehicle.inBudget.sort((a,b) => a.payment - b.payment);

      const vehicleWithLowestPayment = vehicle.inBudget[0]

      getSeriesNameByYear(seriesData, vehicleWithLowestPayment);
 
      inBudgetOffers = [...inBudgetOffers, vehicleWithLowestPayment];
    }
    if (!vehicle.inBudget.length && vehicle.almostInBudget.length) {
      vehicle.almostInBudget.sort((a,b) => a.payment - b.payment);

      const vehicleWithLowestPayment = vehicle.almostInBudget.filter(item => item.underLimit)[0]

      getSeriesNameByYear(seriesData, vehicleWithLowestPayment)
      almostInBudgetOffers = [...almostInBudgetOffers, vehicleWithLowestPayment];
    }
  });

  return {
    inBudgetOffers,
    almostInBudgetOffers
  }
};

export {
  getBudgetArrays
};
