import React from 'react';
import { fireTag } from '.';
import getLinkBlock from './blocks/getLinkBlock';
import { AnalyticsProps } from './models';
import { Link, LinkProps } from 'react-router-dom';

interface AALinkRouterAnchorProps extends AnalyticsProps, Readonly<LinkProps> {}

const AALinkRouterAnchor = ({
  getBlocks,
  linkText,
  onClick = () => {},
  ...rest
}: AALinkRouterAnchorProps) => {

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    const innerText = event.currentTarget.innerText;
    if (getBlocks) {
      const text = linkText ? linkText : innerText;
      fireTag('aa-link',
        {
          ...getBlocks(),
          ...getLinkBlock({
            text, 
            typeTitle: text,
            trigger: 'aa-link',
            type: 'Link',
          }),
        }
      );
    }
    onClick(event);
  };

  return <Link onClick={handleOnClick} {...rest} />
};

export default AALinkRouterAnchor;
