import React, { useEffect, useState, useContext } from 'react';
import styles from './Budget.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Header from '../../../../src/components/Header';
import Tabs from '../../../../src/components/Tabs';
import Container from '../../../../src/components/Container';
import Title from '../../../../src/components/Title';
import BudgetSlider from '../../../../src/components/Slider';
import Button from '../../../../src/components/Button';
import FadeIn from '../../../../src/components/FadeIn';
import { AppStoreContext } from '../../../../src/stores/AppStore';
import { PostMessageStoreContext } from '../../../../src/stores/PostMessageStore';
import { VehiclesResponseItem } from '../../../../src/types/vehiclesResponse.models';
import { fireTag } from '../../../../src/utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import getOfferListBlock from '../../../utils/Analytics/blocks/getOfferListBlock';
import Loading from '../../../../src/components/Loading';
import Error from '../../../../src/modules/Error';
import { getBudgetArrays } from '../../../../src/utils/budgetUtils';
import Modal from '../../../../src/components/Modal';
import Disclaimers from '../../../../src/components/Disclaimers';
import Input from '../../../../src/components/Input';

let _timeout = undefined as any;
const Budget = () => {
  const { t } = useTranslation();
  const {
    AppStore: {
      currentBudgetValueMax,
      currentBudgetValueMin,
      min,
      max,
      getVehicles,
      nextMobileState,
      vehiclesList,
      setBudgetValueMax,
      seriesData
    },
  } = useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight, scrollTop }
  } = React.useContext(PostMessageStoreContext);

  const [ inBudgetOffers, setInBudgetOffers ] = useState<VehiclesResponseItem[]>([]);
  const [ almostInBudgetOffers , setAlmostInBudgetOffers ] = useState([] as VehiclesResponseItem[]);
  const [ budgetMax, setBudgetMax ] = useState(currentBudgetValueMax ? currentBudgetValueMax.toString() : '');
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ openDisclaimers , setOpenDisclaimers ] = React.useState(false);

  const contentSection = 'wfmb_step3_set_budget';

  useEffect(() => {
    (async () => {
      try {
        await getVehicles();
        fireTag(
          'ready',
          {
            metrics: 'aa-pageload',
            ...getGlobalBlock(),
            ...getPageBlock(contentSection),
            ...getOfferListBlock(),
          }
        );
        sendHeight();
        scrollTop();
      } catch(e) {
        setError(true);
      }

      setIsLoaded(true);
    })();
  }, [getVehicles, sendHeight, scrollTop]);

  React.useEffect(() => {
    const { inBudgetOffers, almostInBudgetOffers } = getBudgetArrays(vehiclesList, seriesData);

    inBudgetOffers.sort((a,b) => a.payment - b.payment);
    almostInBudgetOffers.sort((a,b) => a.payment - b.payment);

    setInBudgetOffers(inBudgetOffers);
    setAlmostInBudgetOffers(almostInBudgetOffers);
  }, [vehiclesList, setInBudgetOffers, setAlmostInBudgetOffers, seriesData]);

  const handleNextButton = (text: string) => {
    fireTag('aa-link', {
      ...getGlobalBlock(),
      ...getLinkBlock({
        text, 
        typeTitle: text,
        trigger: 'aa-link',
        buttonAction: 'Next',
        type: 'Button',
      }),
      ...getOfferListBlock(),
    });
    nextMobileState();
  };

  const handleOnSliderChange = async (value: number) => {
    const aaLinkText = value ? value.toString() : undefined;
    fireTag('aa-link', {
      ...getGlobalBlock(),
      ...getPageBlock(contentSection),
      ...getLinkBlock({
        action: 'filter price',
        text: aaLinkText,
        trigger: 'aa-link',
        typeClick: 'price-slider',
        typeTitle: 'Price',
      }),
      ...getOfferListBlock(),
    });

    await getVehicles();
  };

  const handleInputChange = (value: string) => {
    if (parseInt(value) <= max && parseInt(value) >= currentBudgetValueMin) {
      setBudgetValueMax(parseInt(value))
      getVehicles();
    }
  };

  return (
    error ? <Error /> :
    <>
      <Header
        isMobileWorkflow
        onBack={() => {
          fireTag(
            'aa-link',
            {
              ...getGlobalBlock(),
              ...getPageBlock(contentSection),
              ...getLinkBlock({
                url: '/',
                href: '/',
                typeTitle: 'Back',
                trigger: 'aa-link',
                buttonAction: 'Back',
                type: 'Button'
              }),
              ...getOfferListBlock(),
            }
          );
        }}
      />
      { isLoaded ? 
        <Container>
          <Tabs />
          <FadeIn>
            <Title className={styles.typeHeading}>{t('budget.heading')}</Title>
            <sup className={styles.disclaimer} onClick={(e) => {
              e.stopPropagation();
              setOpenDisclaimers(true);
            }}> 1</sup>
            <Modal open={openDisclaimers} onClose={() => setOpenDisclaimers(false)}>
              <Disclaimers onClose={() => setOpenDisclaimers(false)}>
                {t('disclaimers.sliderDisclaimer')}
              </Disclaimers>
            </Modal>
            {/* Budget Slider */}
            <div className={styles.budgetSelector}>
              <p className={styles.budgetWrapper}>
                <span className={styles.upTo}>{t('budget.upTo')}</span>
                
                <span 
                  className={styles.budgetValue}
                >
                  <Input
                    number
                    inputValue={budgetMax}
                    onInputChange={val => {
                      setBudgetMax(val);
                      clearTimeout(_timeout);
                      _timeout = setTimeout(() => {
                        handleInputChange(val);
                      }, 500)
                    }}
                  />
                  <label 
                    htmlFor="budget" 
                    className={styles.perMonthLabel}>
                      {t('budget.label')}
                  </label>
                </span>
                
              </p>
              
              <BudgetSlider 
                min={min}
                max={max}
                hideToolTip={true}
                onChange={value => setBudgetMax(value.toString())}
                onAfterChange={handleOnSliderChange}
              />
            </div>
            <div className={styles.ctaContainer}>
              <Button
                className={styles.nextCta}
                variant='redBackground'
                fullWidth
                onClick={() => handleNextButton(`${t('budget.ctaLabel')} (${inBudgetOffers.length})` )} 
                disabled={currentBudgetValueMax === 0}
              >
                {t('budget.ctaLabel')} ({inBudgetOffers.length + almostInBudgetOffers.length})
              </Button>
            </div>
          </FadeIn>
        </Container> :
        <Loading />
      }

    </>
  );
};

export default observer(Budget);
