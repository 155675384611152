import React, {AnchorHTMLAttributes } from 'react';
import { fireTag } from '.';
import getLinkBlock from './blocks/getLinkBlock';
import { AnalyticsProps } from './models';

interface AALinkAnchorProps extends AnalyticsProps, AnchorHTMLAttributes<HTMLAnchorElement> {}

const AALinkAnchor = ({
  getBlocks,
  linkText,
  onClick = () => {},
  children,
  ...rest
}: AALinkAnchorProps) => {

  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    const innerText = event.currentTarget.innerText;
    if (getBlocks) {
      const text = linkText ? linkText : innerText;
      fireTag('aa-link',
        {
          ...getBlocks(),
          ...getLinkBlock({
            text, 
            typeTitle: text,
            trigger: 'aa-link',
            type: 'Link',
          }),
        }
      );
    }
    onClick(event);
  };

  return <a onClick={handleOnClick} {...rest}>{children}</a>
};

export default AALinkAnchor;
