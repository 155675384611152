import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './Slider.module.scss';

const CustomHandle = ({
 className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return (
      <section className={cx(styles.customHandle, className)} {...rest}>
        <div className={styles.barWrapper}>
          <div className={styles.bar} />
          <div className={styles.bar} />
          <div className={styles.bar} />
        </div>
      </section>
  );
};

export default CustomHandle;
