import React, { HTMLAttributes, ReactNode } from 'react';
import styles from './accordion.module.scss';

interface AccordionHeaderProps extends HTMLAttributes<HTMLElement> {
  onCheck: (value: boolean) => void; 
  toggleText?: ReactNode;
}

const AccordionHeader = ({
  onCheck,
  children,
  toggleText,
  defaultChecked,
}: AccordionHeaderProps) => {
  return (
    <header className={styles.accordionHeader}>
      <label>
        {children}
        <span className={styles.toggleSection}>
          <span className={styles.toggleText}>{ toggleText }</span>
          <input
            type='checkbox'
            defaultChecked={defaultChecked}
            onChange={e =>
              onCheck(e.currentTarget.checked)
            }
          />
          <span className={styles.toggleIcon} />
        </span>
      </label>
    </header>
  );
};

export default AccordionHeader;
