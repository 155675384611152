import React from 'react';
import cx from 'classnames';
import styles from './Title.module.scss';

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const Title = (props: TitleProps) => {

  const { className, children } = props;

  return (
    <h1 className={cx([className, styles.title])}>{children}</h1>
  );
};

export default Title;
