export class Dealer {
  name = '';
  distance = '';
  isPma = false;
  code = '';
  isSelected = false;
  tdaCode = '';
  contact = {
    firstName: '',
    lastName: '',
    region: '',
    street: '',
    city: '',
    zip: '',
    state: '',
    phone: '',
    email: '',
    website: '',
    address: '',
  }

  constructor(pmaItem: any) {
    this.name = pmaItem.dealerParty.specifiedOrganization.companyName.value;
    this.isPma = pmaItem.isPma;
    this.code = pmaItem.dealerParty.partyID.value;
    this.contact.region = pmaItem.dealerParty.specifiedOrganization.divisionCode.value;
    this.distance = pmaItem.proximityMeasureGroup.proximityMeasure.value ?
      pmaItem.proximityMeasureGroup.proximityMeasure.value.toFixed(2) : '';
    this.tdaCode = pmaItem.tdaCode;
    // find main dealer contact
    const foundMainDealer = pmaItem.dealerParty.specifiedOrganization.primaryContact
          .filter((item:any) => { return item.departmentName.value === 'Main Dealer'; });

    if (foundMainDealer.length) {
      this.contact.street = foundMainDealer[0].postalAddress.lineOne.value;
      this.contact.city = foundMainDealer[0].postalAddress.cityName.value;
      this.contact.zip = foundMainDealer[0].postalAddress.postcode.value;
      this.contact.state = foundMainDealer[0].postalAddress.stateOrProvinceCountrySubDivisionID.value;
      this.contact.address = `${this.contact.street} ${this.contact.city}, ${this.contact.state} ${this.contact.zip}`;
      
      // find phone
      const foundPhone = foundMainDealer[0].telephoneCommunication
              .filter((item:any) => { return item.channelCode.value === 'Phone'; });
      if (foundPhone.length) {
        this.contact.phone = foundPhone[0].completeNumber.value;
      }

      // find website and email
      foundMainDealer[0].uricommunication.forEach((item:any) => {
        switch (item.channelCode.value) {
          case 'Email': {
            this.contact.email = item.uriid.value;
            break;
          }
          case 'Website': {
            this.contact.website = item.uriid.value;
            break;
          }
        }

      });

    }
  }
}
