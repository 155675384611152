import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import vehicleCategories from '../../components/VehicleCategory/vehicleCategories.json';
import VehicleCategoryItem from '../../components/VehicleCategory/VehicleCategoryItem';
import useChecklist from '../../hooks/useChecklist';
import { AppStoreContext } from '../../../src/stores/AppStore';
import Title from '../Title';
import styles from './VehicleCategory.module.scss';

interface VehicleCategoryProps {
  onClick: (text: string, index: number) => void;
}

const VehicleCategory = observer((props: VehicleCategoryProps) => {
  const { onClick } = props;
  const { t } = useTranslation();
  const {
    AppStore: { setCategories },
  } = React.useContext(AppStoreContext);
  const { checklist, setChecklist } = useChecklist([]);

  useEffect(() => {
    setChecklist([...vehicleCategories]);
  }, [setChecklist]);

  return (
    <>
      <Title className={styles.typeHeading}>{t('vehicleCategory.heading')}</Title>

      {
        checklist.map((vehCat, index) => {
          return (
            <VehicleCategoryItem
              key={index}
              vehicleCategory={vehCat}
              onClick={ () =>
                {
                  setChecklist([...checklist])
                  setCategories([...checklist]);
                  onClick(vehCat.category, index);
                }
              }
            />
        )})
      }
    </>
  );
});

export default VehicleCategory;
