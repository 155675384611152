import React, { useMemo } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';

import { VehiclesResponseItem } from '../../../../types/vehiclesResponse.models';
import { fireTag } from '../../../../utils/Analytics';
import getGlobalBlock from '../../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../../utils/Analytics/blocks/getPageBlock';
import getLinkBlock from '../../../../utils/Analytics/blocks/getLinkBlock';
import getListItem from '../../../../utils/Analytics/blocks/getListItem';
import getOfferListBlock from '../../../../utils/Analytics/blocks/getOfferListBlock';
import { getOfferBlockFromResponse } from '../../../../utils/Analytics/blocks/getOfferBlock';

import styles from './BudgetOptionItem.module.scss';
import Content from './Content';

interface BudgetOptionItemProps extends React.InputHTMLAttributes<HTMLInputElement> {
  budgetOption: VehiclesResponseItem;
  index: number;
  almostInBudget?: boolean;
  onClick: () => void;
}

const BudgetOptionItem = observer((props: BudgetOptionItemProps) => {
  const { budgetOption, onClick, almostInBudget, index }  = props;

  const taggingBlocks = useMemo(() => ({
    ...getGlobalBlock(),
    ...getPageBlock('wfmb_offer_results'),
    ...getListItem(index),
    ...getOfferListBlock({layout: 'list'}),
    ...getOfferBlockFromResponse(budgetOption),
  }), [budgetOption, index]); 

  const handleOnClick = (text: string) => {
    budgetOption.selected = !budgetOption.selected;

    fireTag('aa-link',
    {
      ...taggingBlocks,
      ...getLinkBlock({
        text, 
        typeTitle: text,
        trigger: 'aa-link',
        buttonAction: 'Toggle',
        type: 'Button'
      }),
    })
    onClick();
  };

  return (
    <>
    {
      !almostInBudget ?
      <button 
        className={cx({
          [styles.optionItem]: true,
          [styles.optionSelected]: budgetOption.selected,
        })}
        onClick={() => {
          handleOnClick(budgetOption.series ? budgetOption.series.toUpperCase() : '');
        }}
      >
        <Content 
          blocks={taggingBlocks}
          budgetOption={budgetOption}
          almostInBudget={almostInBudget}
          budgetOptionSelected={budgetOption.selected}
        />
      </button>
      :
      <div className={styles.optionItem}>
        <Content 
        blocks={taggingBlocks}
          budgetOption={budgetOption}
          almostInBudget={almostInBudget}
        />
      </div>
    }
    </>
  );
});

export default BudgetOptionItem;
