import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import styles from '../ContactDealer.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const Input = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => {

  const { className, type = 'text', ...rest } = props;

  return (
    <input
      type={type}
      ref={ref}
      className={cx([styles.input, className])}
      defaultValue=''
      {...rest}
    />
  );
});

export default Input;
