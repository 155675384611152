import { EFCMSSeriesResponse, SeriesData, SeriesList } from 'types/efcMs.models';

export const refactorSeries = (efcData: EFCMSSeriesResponse) => {
  efcData.series_data.forEach((series: SeriesData) => {
    series.series_list.forEach((data: SeriesList) => {
      if (data.code === 'tacomahybrid') {
        data.title = 'Tacoma i-FORCE MAX';
      }
    });
  });
};
