import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { VehiclesResponse } from '../../../types/vehiclesResponse.models';
import { AppStoreContext } from '../../../stores/AppStore';
import useScrollVisible from '../../../hooks/useScrollVisible';
import { fireTag } from '../../../utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getOfferVehicleBlock from '../../../utils/Analytics/blocks/getOfferVehicleBlock';
import CarBanner from '../../../components/CarBanner';
import FinanceInfo from '../../../components/FinanceInfo';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import { getOfferBlockFromResponse } from '../../../utils/Analytics/blocks/getOfferBlock';
import Container from '../../../components/Container';
import TrimOptionItem from './TrimOptionItem';

import styles from './SelectTrim.module.scss';
import { getVehicleBlockFromResponse } from '../../../utils/Analytics/blocks/getVehicleBlock';
import { observer } from 'mobx-react-lite';

interface SeriesOptionProps {
  vehicle: VehiclesResponse;
  index: number;
  contentSection: string;
}

const SeriesOption = (props: SeriesOptionProps) => {

  const {
    AppStore: {
      scrollPosition,
      setSelectedSeries,
      setSelectedTrim
  }} = React.useContext(AppStoreContext);
  const { contentSection, vehicle, index } = props;
  const { t } = useTranslation();
  const { ref, setScroll } = useScrollVisible({
    onVisible: () => {
      if (index) {
        const { model, grade, category, series, year, } = vehicle.inBudget[0]

        fireTag(
          'aa-subpageload',
          {
            ...getGlobalBlock(),
            ...getPageBlock(contentSection),
            ...getOfferVehicleBlock({
              number: model,
              grade: grade,
              category: category,
              code: series,
              year: year,
            }, true),
            ...getOfferBlockFromResponse(vehicle.inBudget[0]),
            ...getVehicleBlockFromResponse(vehicle.inBudget[0]),
          }
        );
      }
    }
  }); 

  useEffect(() => {
    setScroll(scrollPosition);
  }, [setScroll, scrollPosition]);

  setSelectedTrim(vehicle.inBudget[0]);

  return (
    <div className={styles.trimOptionWrapper} ref={ref}>
      <CarBanner carImage={vehicle.image} />
      <FinanceInfo
        selectedTrim={vehicle.inBudget[0]}
        onRaqOpen={(text) => {
          fireTag('aa-link', {
            ...getGlobalBlock(),
            ...getPageBlock(contentSection),
            ...getLinkBlock({
              text,
              typeTitle: text,
              trigger: 'aa-link',
              buttonAction: 'Open Modal',
              type: 'Button'
            }),
            ...getOfferBlockFromResponse(vehicle.inBudget[0]),
          });
        }}
      />

      {/* In Budget */}
      <div className={styles.inBudgetWrapper}>
        <Container className={styles.trimContainer}>
          <label htmlFor="inYourBudget" className={styles.optionsLabel}>{t('selectTrim.inBudget')}</label>
          {
            vehicle && vehicle.inBudget.map((trim, index) => {
              return (

                <TrimOptionItem
                  key={index}
                  trimOption={trim}
                  contentSection={contentSection}
                  onClick={() => setSelectedSeries(vehicle)}
                />
              )
            })
          }
        </Container>
      </div>

      {/* Almost In Budget */}
      { vehicle.almostInBudget.length > 0 &&
        <div className={styles.almostInBudgetWrapper}>
          <Container className={styles.trimContainer}>
            <label htmlFor="almostIYourBudget" className={styles.optionsLabel}>{t('selectTrim.almostInBudget')}</label>
            {
              vehicle && vehicle.almostInBudget.map((trim, index) => {
                return (
                  <TrimOptionItem
                    key={index}
                    almostInBudget
                    contentSection={contentSection}
                    trimOption={trim}
                    onClick={() => {
                      setSelectedSeries(vehicle);
                    }}
                  />
                )
              })
            }
          </Container>
        </div>
      }
    
    </div>
  );
};

export default observer(SeriesOption);
