import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './Input.module.scss';

type InputValueType = string | number | string[] | undefined;

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  number?: boolean;
  inputValue?: InputValueType;
  onInputChange?: (value: string) => void;
}

const Input = ({
  number,
  inputValue,
  value,
  className,
  onInputChange = () => {},
  ...rest
}: InputProps) => {

  const [ val, setVal ] = useState<InputValueType>(inputValue);

  useEffect(() => {
    setVal(inputValue);
  }, [inputValue])

  return (
    <input 
      type='text'
      className={cx(styles.input, className)} 
      value={val}
      onChange={e => {
        let currentVal = e.currentTarget.value;
        if (number) {
          currentVal = currentVal.replace(/[^\d]/g, '');
        } 
        setVal(currentVal);
        onInputChange(currentVal);
      }}
      {...rest} 
    />
  );
};

export default Input;
