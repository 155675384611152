import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppStore, { AppStoreContext } from '../../../stores/AppStore';
import ModalClose from '../../Modal/ModalClose';
import Button from '../../Button';
import { Dealer } from '../../../types/dealer.models';
import cookie from 'js-cookie';

import { fireTag } from '../../../utils/Analytics';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import { VehiclesResponseItem } from '../../../types/vehiclesResponse.models';
import { getVehicleBlockFromResponse } from '../../../utils/Analytics/blocks/getVehicleBlock';
import { getDealerBlockFromResponse } from '../../../utils/Analytics/blocks/getDealerBlock';
import { getOfferBlockFromResponse } from '../../../utils/Analytics/blocks/getOfferBlock';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import slpFormCampaignCode from '../../../utils/Analytics/blocks/slpFormCampaignCode';

import styles from '../ContactDealer.module.scss';
import getTransactionID from '../../../utils/Analytics/blocks/getTransactionID';

interface SuccessProps {
  onClose: () => void;
  dealer: Dealer;
  selectedTrim: VehiclesResponseItem;
}

export const createRandomIdentifier = () => {
  const xactCookie = cookie.get("xact");

  if (xactCookie) {
    return xactCookie;
  } else if (AppStore.xact) {
    return AppStore.xact;
  } else {
    return `${crypto.getRandomValues(new Uint8Array(1))[0] / 255}`;
  }
};

const Success = ({
  onClose,
  dealer,
  selectedTrim,
}: SuccessProps) => {
  const {
    AppStore: {
      campaignCode
    }
  } = React.useContext(AppStoreContext); 

  const { t } = useTranslation();
  const tContinueBtn = t('raq.continueBtn');
  const contentSection = 'wfmb_raq_overlay_confirmation';


  useEffect(() => {
    fireTag('ready', {
      metrics: 'km-raq-complete',
      ...getPageBlock(contentSection),
      ...getVehicleBlockFromResponse(selectedTrim),
      ...getDealerBlockFromResponse(dealer),
      ...getOfferBlockFromResponse(selectedTrim),
      ...getTransactionID(createRandomIdentifier()),
      ...slpFormCampaignCode(campaignCode),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer, selectedTrim, campaignCode, createRandomIdentifier()]);

  return (
    <section className={styles.success}>
      <ModalClose close={() => {
        fireTag('aa-link', {
          ...getLinkBlock({
            url: '/',
            href: '/',
            typeTitle: 'Close',
            trigger: 'aa-link',
            buttonAction: 'Close',
            type: 'Button'
          }),
          ...getPageBlock(contentSection),
          ...getVehicleBlockFromResponse(selectedTrim),
          ...getDealerBlockFromResponse(dealer),
          ...getOfferBlockFromResponse(selectedTrim),
        });
        onClose();
      }} />
      <h3>{t('raq.successTitle')}</h3>
      <p>
        {t('raq.sentToDealer')} {dealer.name}
      </p>
      <p>{t('raq.successMsg1')}</p>
      <p>{t('raq.successMsg2')}</p>
      <Button
        variant='redBackground'
        onClick={() => {
          fireTag('aa-link', {
            ...getLinkBlock({
              url: '/',
              href: '/',
              text: tContinueBtn,
              typeTitle: tContinueBtn,
              trigger: 'aa-link',
              buttonAction: 'Click',
              type: 'Button'
            }),
            ...getPageBlock(contentSection),
            ...getVehicleBlockFromResponse(selectedTrim),
            ...getDealerBlockFromResponse(dealer),
            ...getOfferBlockFromResponse(selectedTrim),
          });
          onClose();
        }}
      >
        {tContinueBtn}
      </Button>
    </section>
  );
};

export default Success;
