import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './accordion.module.scss';

const Accordion = ({
  className,
  ...rest
}: HTMLAttributes<HTMLElement>) => {
  return (
    <section className={cx(styles.accordion, className)} {...rest} />
  );
};

export default Accordion;
