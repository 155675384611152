import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './BudgetDesktop.module.scss';

interface DesktopWrapperProps extends HTMLAttributes<HTMLDivElement> {
  viewOffersOpen: boolean;
}

const DesktopWrapper = ({
  viewOffersOpen,
  ...props
}: DesktopWrapperProps) => {
  return (
    <section className={cx({
      [styles.budgetDesktop]: true,
      [styles.viewOffersOpen]: viewOffersOpen,
      [styles.budgetDesktopSection]: !viewOffersOpen
    })}>
      <section {...props} className={cx(
        styles.desktopWrapper,{[styles.desktopWrapperSection]: !viewOffersOpen})} />
    </section>
  );
};

export default DesktopWrapper;
