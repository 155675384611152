import { VehiclesResponseItem } from "../types/vehiclesResponse.models";
import { getTdaByCode } from "./tdaUtils";

const getPeLink = (tdaCode: string, zip: string, offer: VehiclesResponseItem, lang: string, domain: string) => {
  if(domain === 'toyota'){
    return buildTCOMLink(buildParams(zip, offer), lang);
  } else {
    return buildBATLink(buildParams(zip, offer), tdaCode, lang);
  }
};

const buildParams = (zip: string, offer: VehiclesResponseItem) => {
  const params = [
    `zip=${zip}`,
    `year=${offer.year}`,
    `series=${offer.series}`,
    `tab=${offer.purchaseType === "buy" ? "finance" : "lease"}`,
  ]

  if (offer.offers[0]?.offerId) {
    params.push(`offer=${offer.offers[0].offerId}`); 
  } 

  params.push(
    offer.offers[0]?.offerId && offer.offers[0]?.modelCode
      ? `trim=${offer.offers[0].modelCode}`
      : `trim=${offer.model}`
  );

  return params.join('&');
};

const buildBATLink = (params: string, tdaCode: string, lang: string) => {
  const langParam = lang === 'es' ? '/es' : '';
  return `${process.env.REACT_APP_BAT_PE_DOMAIN_URL}${langParam}/${getTdaByCode(tdaCode)}/payment-estimator?${params}`;
};

const buildTCOMLink = (params: string, lang: string) => {
  const langParam = lang === 'es' ? '/espanol' : '';
  return `${process.env.REACT_APP_TCOM_PE_DOMAIN_URL}${langParam}/payment-estimator?${params}`;
};

const buildDisclaimerLink = (lang: string) => {
  if (lang ==='es') {
    return 'https://www.toyota.com/espanol/support/legal-terms';
  } else {
    return 'https://www.toyota.com/support/legal-terms';
  } 
}

const showPeLink = (almostInBudget: boolean, option: VehiclesResponseItem) => {
  return Boolean(almostInBudget || showPeLinkOffer(option));
};

const showPeLinkOffer = (option: VehiclesResponseItem) => {
  if (!option.offer) {
    return true;
  } else {
    if (
        !option.offer.offerId || // standard rate is used
        (
          option.purchaseType === 'lease' &&
          option.model !== option.offer.advertisedTrim
        )
       )
    {
      return true;
    }

    return false;
  }
  
};

export {
  buildDisclaimerLink,
  getPeLink,
  showPeLink,
};
