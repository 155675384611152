import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { fireTag } from '../../../../utils/Analytics';
import getGlobalBlock from '../../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../../utils/Analytics/blocks/getPageBlock';
import getLinkBlock from '../../../../utils/Analytics/blocks/getLinkBlock';
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import FilterMenu from './FilterMenu';

import styles from './BudgetOptionsHeader.module.scss';

interface BudgetOptionsHeaderProps {
  numOfVehicles: number;
  contentSection: string;
}

const BudgetOptionsHeader = observer((props: BudgetOptionsHeaderProps) => {

  const { numOfVehicles, contentSection } = props;
  const [ openFilter , setOpenFilter ] = React.useState(false);

  const { t } = useTranslation();

  const handleOpen = () => {
    fireTag(
      'aa-link',
      {
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getLinkBlock({
          text: 'Filter',
          typeTitle: 'Filter',
          trigger: 'aa-link',
          buttonAction: 'Open',
          type: 'Button'
        })
      }
    );
    setOpenFilter(true);
  };

  return (
    <div className={styles.header}>
      <Container>
        <div className={styles.headerContainer}>
          <span className={styles.headingLabel}>
            <span className={styles.numOfVehicles}>{numOfVehicles} {t('budgetOptionsHeader.vehicles')} </span>
            {t('budgetOptionsHeader.inYourBudget')}
          </span>
          <Button 
            className={styles.filterCta}
            variant='defaultCta'
            onClick={handleOpen}
          >
            {t('header.filterCta')}
          </Button>
          <Modal
            open={openFilter}
            onClose={() => setOpenFilter(false)}
          >
            <FilterMenu onClose={() => setOpenFilter(false)} />
          </Modal>
        </div>
      </Container>
    </div> 
  );
});

export default BudgetOptionsHeader;
