import React, { useEffect } from 'react';
import AppStore from '../stores/AppStore';

interface UseScrollVisibleProps {
  onVisible: () => void;
}

const useScrollVisible = (props: UseScrollVisibleProps) => {

  const { onVisible } = props;
  const ref: any = React.useRef(null);
  const [ isInView, setIsInView ] = React.useState(false);

  const handleScroll = () => {
    if (ref) {
      const { top } = ref.current.getBoundingClientRect();
      const isInViewport = top >= 0 && top <= window.innerHeight;
      if (isInViewport) {
        setIsInView(true);
        if (!isInView) {
          onVisible();
        }
      }
    }
  };

  const setScroll = (scrollPosition: number) => {
    const { top } = ref.current.getBoundingClientRect();
    const offsetTop = top - AppStore.clientHeight;
    const isInViewport = scrollPosition > offsetTop;
    if (isInViewport) {
      setIsInView(true);
      if (!isInView) {
        onVisible();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  return {
    ref,
    isInView,
    setScroll,
  }
}

export default useScrollVisible;
