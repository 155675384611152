import { API } from './api';
import { WFMBVehiclesRequest, WFMBVehiclesResponse } from './models/wfmb.models';
import { AxiosPromise } from 'axios';

export const getVehicles = ({
  tdaCode,
  state,
  purchaseType,
  pricingArea,
  minBudget,
  maxBudget,
  series
}: WFMBVehiclesRequest): AxiosPromise<WFMBVehiclesResponse[]> => {
  const params = [
    `tdaCode=${tdaCode}`,
    `state=${state}`,
    `purchaseType=${purchaseType}`,
    `pricingArea=${pricingArea}`,
    `minBudget=${minBudget}`,
    `maxBudget=${maxBudget}`,
    `series=${series}`,
  ].join('&');
  const url = `${process.env.REACT_APP_WEB_API}/v1/vehicles?${params}`;
  return API({
    method: 'GET',
    url: url,
    headers: { 'x-api-key': process.env.REACT_APP_WEB_API_KEY }
  });
};
