import AppStore from "../../../stores/AppStore";
import { getBudgetArrays } from "../../../utils/budgetUtils";

type OfferListBlockLayoutType = 'grid' | 'list' | 'strip' | 'tile' | 'banner';

interface OfferListBlock {
  layout?: OfferListBlockLayoutType;
  includeAlmost?: boolean;
}

const getOfferListBlock = (props?: OfferListBlock) => {
  const { financeTypes, vehiclesList, categories, } = AppStore;
  const { inBudgetOffers, almostInBudgetOffers } = getBudgetArrays(vehiclesList);

  const financeSelection = financeTypes.map(type => type.toLowerCase());
  const categorySelection = categories.filter(item => item.selected).map(item => item.category);
  const filters = financeSelection.concat(categorySelection).join(',');

  return {
    offer_list_filter: filters,
    offer_list_selector: filters,
    offer_list_layout: props ? props.layout : undefined,
    offer_list_result_count: inBudgetOffers.length + almostInBudgetOffers.length,
  };
};

export default getOfferListBlock;
