import React from 'react';
import { Range, Handle } from 'rc-slider';
import "rc-slider/assets/index.css";
import { AppStoreContext } from '../../stores/AppStore';
import { observer } from 'mobx-react';
import CustomHandle from './CustomHandle';

interface BudgetRangeProps {
  onChange?: (min: number, max: number) => void;
  onAfterChange?: (min: number, max: number) => void; 
}

const BudgetRange = observer((props: BudgetRangeProps) => {

  const { onChange = () => {}, onAfterChange = () => {} } = props;

  const {
    AppStore: {
      min,
      max,
      currentBudgetValueMin,
      currentBudgetValueMax,
      setBudgetValueMin,
      setBudgetValueMax,
    }
  } = React.useContext(AppStoreContext);

  return (
    <Range
      min={min}
      max={max}
      allowCross={false}
      value={[currentBudgetValueMin, currentBudgetValueMax]}
      handle={({dragging, index, ...handleProps}) => 
        <Handle key={index} dragging={dragging.toString()} {...handleProps}>
          <CustomHandle />
        </Handle>
      }
      onChange={ value => {
        setBudgetValueMin(value[0]);
        setBudgetValueMax(value[1]);
        onChange(value[0], value[1]);
      }}
      onAfterChange={async () => {
        onAfterChange(currentBudgetValueMin, currentBudgetValueMax);
      }}
    />
  );
});

export default BudgetRange;
