/**
 * useChecklist
 * 
 * Accepts an array of any object. Object will work off the selected field
 */
import { useState, useEffect } from 'react';

const useChecklist = (initArray: Array<any>) => {
  const [ checklist, setChecklist ] = useState(initArray);
  const [ hasChecked, setHasChecked ] = useState(false);

  useEffect(() => {
    setHasChecked(checklist.filter(item => item.selected).length > 0);
  }, [checklist, setHasChecked]);

  return { checklist, setChecklist, hasChecked };
};

export default useChecklist;
