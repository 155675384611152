import React, { ReactNode, useState } from 'react';
import AccordionHeader from './AccordionHeader';
import AccordionSection from './AccordionSection';
import styles from './accordion.module.scss';

interface AccordionItemProps {
  header: ReactNode;
  section: ReactNode;
  toggleText?: ReactNode;
  defaultExpanded?: boolean;
  onChecked?: (value: boolean) => void;
}

const AccordionItem = ({
  header,
  section,
  toggleText,
  defaultExpanded = false,
  onChecked = () => {},
}: AccordionItemProps) => {

  const [ expand, setExpand ] = useState(defaultExpanded);

  const handleOnCheck = (value: boolean) => {
    setExpand(value);
    onChecked(value);
  }

  return (
    <div className={styles.accordionItem}>
      <AccordionHeader onCheck={handleOnCheck} toggleText={toggleText} defaultChecked={expand}>{header}</AccordionHeader>
      <AccordionSection expand={expand}>{section}</AccordionSection>
    </div>
  );
};

export default AccordionItem;
