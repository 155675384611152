import React from 'react';
import styles from './CarBanner.module.scss';

interface CarBannerProps {
  carImage: string;
}

const CarBanner = (props: CarBannerProps) => {

  const { carImage } = props;

  return (
    <section className={styles.carBanner}>
      <img src={carImage} alt={carImage} className={styles.carJellyImage}/>
    </section>
  );
};

export default CarBanner;
