import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../BudgetDesktop.module.scss';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import { PostMessageStoreContext } from '../../../stores/PostMessageStore';
import { AppStoreContext } from '../../../stores/AppStore';

interface AdditionalOptionsProps {
  taggingBlocks: any;
  checklist: any[];
  onCategoryChecked: () => void;
  onFinanceTypeChecked: (finance: boolean, lease: boolean) => void;
}

const AdditionalOptions = ({
  taggingBlocks,
  checklist,
  onCategoryChecked,
  onFinanceTypeChecked,
}: AdditionalOptionsProps) => {

  const [ showOptions, setShowOptions ] = useState(false);
  const { t } = useTranslation();

  const { AppStore: {
    isFinanceChecked,
    isLeaseChecked,
    setIsFinanceChecked,
    setIsLeaseChecked
  }} = React.useContext(AppStoreContext);
  const {
    postMessageStore: { sendHeight }
  } = React.useContext(PostMessageStoreContext);

  useEffect(() => {
    sendHeight();
  }, [showOptions, sendHeight]);

  return (
    <section className={styles.additionalOptions}>
      <div className={cx({
        [styles.additionalOptionsCta]: true,
        [styles.toggled]: showOptions
      })}>
        <Button
          className={styles.additionalOptionsBtn}
          variant='toggleArrow'
          toggled={showOptions}
          onClick={() => {
            setShowOptions(!showOptions);
            sendHeight();
          }}
          aria-expanded={showOptions}
          getBlocks={() => taggingBlocks}
          buttonAction='Toggle'
        >
          {t('budgetInterstitial.additionalOptions')}
        </Button>
      </div>
      <div
        className={cx({
          [styles.optionsRow]: true,
          [styles.toggled]: showOptions
        })}
      >

        {/* Vehicle Category Filters */}
        <label 
          htmlFor="vehiclefilter" 
          id="vehiclefilter" 
          className={styles.vehiclefilter}
        >
          select autos
        </label>
        <ul className={styles.categoryList} aria-labelledby="vehiclefilter">
          { checklist.map(cat =>
            <li key={cat.name} aria-live='polite' aria-controls={cat.category}>
              <Checkbox
                id={`chbox-int-${cat.name}`}
                className={styles.checkbox}
                key={cat.name}
                checked={cat.selected}
                onChecked={checked => {
                  cat.selected = checked;
                  onCategoryChecked();
                }}
                getBlocks={() => taggingBlocks}
              >
                {t(`categories.${cat.name}`)}
              </Checkbox>
            </li>
            
          )}
        </ul>

        {/* Finance/Lease Checkboxes */}
        <label 
          htmlFor="financingOptions" 
          id="financingOptions" 
          className={styles.financingOptions}
        >
          select autos
        </label>
        <ul className={styles.financeTypes} aria-labelledby="financingOptions">
          <li aria-live='polite' aria-controls='finance'>
            <Checkbox
              id={`chbox-int-finance`}
              className={styles.checkbox}
              checked={isFinanceChecked}
              onChecked={async checked => {
                setIsFinanceChecked(checked);
                onFinanceTypeChecked(checked, isLeaseChecked);
              }}
              getBlocks={() => taggingBlocks}
            >
              {t('budgetInterstitial.finance')}
            </Checkbox>
          </li>
          <li  aria-live='polite' aria-controls='lease'>
            <Checkbox
              id={`chbox-int-lease`}
              className={styles.checkbox}
              checked={isLeaseChecked}
              onChecked={async checked => {
                setIsLeaseChecked(checked);
                onFinanceTypeChecked(isFinanceChecked, checked);
              }}
              getBlocks={() => taggingBlocks}
            >
              {t('budgetInterstitial.lease')}
            </Checkbox>
          </li>
          
        </ul>
      </div> 
    </section>
  );
};

export default AdditionalOptions;
