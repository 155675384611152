import getVehicleBlock, { VehicleBlock } from "./getVehicleBlock";
import AppStore from "../../../stores/AppStore";
import { VehiclesResponseItem } from "../../../types/vehiclesResponse.models";

const getOfferVehicleBlock = (vehicle: VehicleBlock, copyToVehicleBlock = false) => {

  const { brand } = AppStore;

  let vehicleBlock = {
    offer_series_number: vehicle.number,
    offer_series_msrp: vehicle.msrp,
    offer_series_brand: brand,
    offer_series_grade: vehicle.grade,
    offer_series_engine: vehicle.engine,
    offer_series_transmission: vehicle.transmission,
    offer_series_category: vehicle.category,
    offer_series_accessories_name: vehicle.accessoriesName,
    offer_series_color_ext: vehicle.colorExt,
    offer_series_color_int: vehicle.colorInt,
    offer_series_color_ext_code: vehicle.colorExtCode,
    offer_series_color_int_code: vehicle.colorIntCode,
    offer_series_code: vehicle.code,
    offer_series_year: vehicle.year,
    offer_series_fuel_type: vehicle.fuelType,
    offer_series_packages: vehicle.packages,
    offer_series_body_style: vehicle.bodyStyle,
    offer_series_inventory_type: vehicle.inventoryType,
    offer_series_vehicleid: vehicle.vehicleId,
  }

  if (copyToVehicleBlock) {
    vehicleBlock = {
      ...vehicleBlock,
      ...getVehicleBlock(vehicle)
    }
  }

  return vehicleBlock;
};

export const getOfferVehicleBlockFromResponse = (response: VehiclesResponseItem, copyToVehicleBlock = false) => {
  const { series, year, model, category, grade } = response;
  return getOfferVehicleBlock({
    code: series,
    number: model,
    year,
    category,
    grade,
  }, copyToVehicleBlock);
};

export default getOfferVehicleBlock;
