import React from 'react';
import { observer } from 'mobx-react';

import { AppStoreContext } from '../../../stores/AppStore';
import { PostMessageStoreContext } from '../../../stores/PostMessageStore';
import getOfferVehicleBlock from '../../../utils/Analytics/blocks/getOfferVehicleBlock';
import { fireTag } from '../../../utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import Header from '../../../components/Header';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import FadeIn from '../../../components/FadeIn';
import SeriesOption from './SeriesOption';
import { getOfferBlockFromResponse } from '../../../utils/Analytics/blocks/getOfferBlock';
import { getVehicleBlockFromResponse } from '../../../utils/Analytics/blocks/getVehicleBlock';

const SelectTrim = observer(() => {
  const {
    AppStore: {
      seriesOptions,
      domain,
      assignCampaignCode,
    },
  } = React.useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight, scrollTop }
  } = React.useContext(PostMessageStoreContext);

  const contentSection = 'wfmb_trim_selection';

  React.useEffect(() => {

    const budgetOption = seriesOptions.length ? seriesOptions[0].inBudget[0] : undefined;
    const offerVehicleBlock = budgetOption ?
      {
        ...getOfferVehicleBlock(budgetOption, true),
        ...getOfferBlockFromResponse(budgetOption),
        ...getVehicleBlockFromResponse(budgetOption),
      } : {};

    scrollTop();
    sendHeight();
    fireTag(
      'ready',
      {
        metrics: 'aa-pageload',
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...offerVehicleBlock,
      }
    );
  }, [seriesOptions, scrollTop, sendHeight])

  React.useEffect(() => {
    switch (domain) {
      case 'buyatoyota' : { 
        assignCampaignCode(`${process.env.REACT_APP_SLP_BAT_CAMPAIGN_CODE_MOBILE_TRIM}`); 
        break; 
      }
      case 'toyota' : { 
        assignCampaignCode(`${process.env.REACT_APP_SLP_TCOM_CAMPAIGN_CODE_MOBILE_TRIM}`); 
        break; 
      }
    }
  }, [domain, assignCampaignCode]);

  return (
    <>
      <Header
        isMobileWorkflow
        onBack={() => {
          fireTag(
            'aa-link',
            {
              ...getGlobalBlock(),
              ...getPageBlock(contentSection),
              ...getLinkBlock({
                url: '/',
                href: '/',
                typeTitle: 'Back',
                trigger: 'aa-link',
                buttonAction: 'Back',
                type: 'Button'
              }),
            }
          );
        }}
      />
        <FadeIn>
        {
          seriesOptions.map((vehicle, index) =>
            <SeriesOption
              vehicle={vehicle}
              key={index}
              index={index}
              contentSection={contentSection}
            />
          )
        }
      </FadeIn>
    </>
  );
});

export default SelectTrim;
