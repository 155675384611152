import { VehiclesResponseItem } from "../../../types/vehiclesResponse.models";

export interface VehicleBlock {
  number?: string; // model code
  msrp?: string;
  brand?: string;
  grade?: string;
  engine?: string;
  transmission?: string;
  category?: string;
  accessoriesName?: string;
  colorExt?: string;
  colorInt?: string;
  colorExtCode?: string;
  colorIntCode?: string;
  code?: string;
  year?: string;
  fuelType?: string;
  packages?: string;
  bodyStyle?: string;
  inventoryType?: string;
  vehicleId?: string;
}

const getVehicleBlock = (series: VehicleBlock) => ({
  series_number: series.number,
  series_msrp: series.msrp,
  series_brand: series.brand,
  series_grade: series.grade,
  series_engine: series.engine,
  series_transmission: series.transmission,
  series_category: series.category,
  series_accessories_name: series.accessoriesName,
  series_color_ext: series.colorExt,
  series_color_int: series.colorInt,
  series_color_ext_code: series.colorExtCode,
  series_color_int_code: series.colorIntCode,
  series_code: series.code,
  series_year: series.year,
  series_fuel_type: series.fuelType,
  series_packages: series.packages,
  series_body_style: series.bodyStyle,
  series_inventory_type: series.inventoryType,
  series_vehicleid: series.vehicleId,
});

export const getVehicleBlockFromResponse = (response: VehiclesResponseItem) => {
  const { series, model, year, category, grade } = response;
  return getVehicleBlock({
    code: series,
    number: model,
    year,
    category,
    grade, 
  });
}

export default getVehicleBlock;
