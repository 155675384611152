import React from "react";
import styles from "./VehicleResults.module.scss";
import { useTranslation } from "react-i18next";
import VehicleCard from "./VehicleCard";
import { VehiclesResponseItem } from "../../../../src/types/vehiclesResponse.models";
import { fireTag } from "../../../../src/utils/Analytics";
import getGlobalBlock from "../../../utils/Analytics/blocks/getGlobalBlock";
import getPageBlock from "../../../utils/Analytics/blocks/getPageBlock";
import getOfferListBlock from "../../../utils/Analytics/blocks/getOfferListBlock";

interface VehicleResultsProps {
  inBudgetOffers: VehiclesResponseItem[];
  almostInBudgetOffers: VehiclesResponseItem[];
}

const VehicleResults = (props: VehicleResultsProps) => {
  const { inBudgetOffers, almostInBudgetOffers } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    fireTag("ready", {
      metrics: "km-offers-search,aa-page-load",
      ...getGlobalBlock(),
      ...getPageBlock("wfmb_offer_results"),
      ...getOfferListBlock({ layout: "grid" }),
    });
  }, []);

  return (
    <section className={styles.vehicleResults}>
      <div>
        <span className={styles.totalVehicles}>
          {inBudgetOffers.length + almostInBudgetOffers.length}{" "}
          {t("budgetInterstitial.inbudgetVehiclesLabel")}
        </span>
        <div className={styles.container}>
          <div className={styles.vehicleContainer}>
            {inBudgetOffers.map((offer, index) => (
              <VehicleCard key={index} index={index} offer={offer} />
            ))}
          </div>
        </div>
        <div>
          {almostInBudgetOffers.length > 0 && (
            <>
              <div className={styles.labelContainer}>
                <label htmlFor="almostInBudget">
                  {t("vehicleResults.almostInBudget")}
                </label>
              </div>
              <div className={styles.container}>
                <div className={styles.vehicleContainer}>
                  {almostInBudgetOffers.map((offer, index) => (
                    <VehicleCard
                      key={index}
                      index={index}
                      offer={offer}
                      almostInBudget
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default VehicleResults;
