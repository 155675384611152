import cx from "classnames";
import { observer } from "mobx-react";
import React, { useContext, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Disclaimers from "../../../../components/Disclaimers";
import DisclaimersModal from "../../../../components/DisclaimersModal";
import NumberFormat from "../../../../components/NumberFormat";
import { AppStoreContext } from "../../../../stores/AppStore";
import { VehiclesResponseItem } from "../../../../types/vehiclesResponse.models";
import AALinkAnchor from "../../../../utils/Analytics/AALinkAnchor";
import AALinkRouterAnchor from "../../../../utils/Analytics/AALinkRouterAnchor";
import { getPeLink, showPeLink } from "../../../../utils/peLinkUtil";
import styles from "./BudgetOptionItem.module.scss";

interface ContentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  budgetOption: VehiclesResponseItem;
  almostInBudget?: boolean;
  budgetOptionSelected?: boolean;
  blocks: any;
}

const Content = ({
  budgetOption,
  almostInBudget = false,
  budgetOptionSelected,
  blocks,
}: ContentProps) => {
  const {
    AppStore: { tdaCode, zip, setSelectedTrim, language, filters, domain },
  } = useContext(AppStoreContext);

  const { t } = useTranslation();
  const tDisclaimers = [
    t("disclaimers.estimateIncludes"),
    t("disclaimers.estimatePayments"),
    t("disclaimers.estimateCredit"),
    t("disclaimers.estimateQualify"),
  ].join(" ");

  const purchaseType =
    budgetOption.purchaseType === "lease" ? "Lease" : "Finance";

  const [openDisclaimers, setOpenDisclaimers] = useState(false);

  return (
    <>
      <div className={styles.headerWrapper}>
        <div
          className={cx({
            [styles.purchaseTypeLease]: purchaseType === "Lease",
            [styles.purchaseTypeFinance]: purchaseType === "Finance",
          })}
        >
          {purchaseType}&nbsp;
          <span className={styles.startingLbl}>{t("common.startingAt")}</span>
        </div>

        {!almostInBudget && (
          <div>
            <div>
              {budgetOption.offer && budgetOption.offer.offerId && (
                <span className={styles.expDate}>
                  {t("budgetOptions.expDate")}{" "}
                  <Moment format="MM/DD/YY">
                    {budgetOption.offer.endDate}
                  </Moment>
                </span>
              )}
            </div>
            <div
              className={cx({
                [styles.chbox]: true,
                [styles.selected]: budgetOptionSelected,
              })}
            />
          </div>
        )}
      </div>
      {/* Monthly payment */}
      <div className={styles.optionPayment}>
        {Math.round(budgetOption.payment)}
        <label htmlFor="perMonth"> {t("budgetOptions.label")}</label>
        <sup
          className={cx(styles.disclaimer, {
            [styles.almstBgtSup]: almostInBudget,
          })}
          onClick={(e) => {
            e.stopPropagation();
            setOpenDisclaimers(true);
          }}
        >
          {" "}
          1
        </sup>
      </div>
      {/* Car jelly */}
      <img
        src={budgetOption.carJellyImage}
        alt="carsImages"
        className={styles.carJelly}
      />
      {/* Series name */}
      <label htmlFor="seriesName" className={styles.seriesLabel}>
        {ReactHtmlParser(
          `${budgetOption.year} ${budgetOption.seriesName}` || ""
        )}
      </label>
      <div className={styles.content}>
        {/* Offer Details */}
        <div className={styles.offerDetails}>
          <div className={styles.detail}>
            {budgetOption.term} <span>{t("budgetOptions.mos")}</span>
          </div>

          <div className={styles.separator}></div>
          {purchaseType === "Lease" && (
            <div className={styles.detail}>
              <div className={styles.due}>
                <NumberFormat
                  dollar
                  value={budgetOption.dueAtSigning}
                  className={styles.dollarSign}
                />
              </div>
              <span>{t("common.dueAtSigning")}</span>
            </div>
          )}
          {purchaseType === "Finance" && (
            <>
              <div className={styles.detail}>
                {Number(budgetOption.rate)}%
                <span> {t("budgetOptions.apr")}</span>
              </div>
              <div className={styles.separator}></div>
              <div className={styles.detail}>
                <div className={styles.due}>
                  <NumberFormat
                    dollar
                    value={budgetOption.downPayment}
                    className={styles.dollarSign}
                  />
                </div>
                <span>{t("budgetOptions.downPayment")}</span>
              </div>
            </>
          )}
        </div>
        {/* Offer Details CTA / Expiration */}
        <div>
          {showPeLink(almostInBudget, budgetOption) ? (
            <AALinkAnchor
              href={getPeLink(tdaCode, zip, budgetOption, language, domain)}
              getBlocks={() => blocks}
              className={styles.offerDetailsCta}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("vehicleCard.viewPayment")}
            </AALinkAnchor>
          ) : (
            <AALinkRouterAnchor
              getBlocks={() => blocks}
              to={`/vehicleDetails/${budgetOption.series}/${budgetOption.model}/${budgetOption.purchaseType}?zip=${zip}/?lang=${language}/?filters=${filters}`}
              className={styles.offerDetailsCta}
              onClick={() => setSelectedTrim(budgetOption)}
            >
              {t("budgetOptions.offerDetails")}
            </AALinkRouterAnchor>
          )}
        </div>
      </div>

      <DisclaimersModal
        open={openDisclaimers}
        onClose={() => setOpenDisclaimers(false)}
        className={styles.disclaimersModal}
      >
        <Disclaimers onClose={() => setOpenDisclaimers(false)}>
          {tDisclaimers}
        </Disclaimers>
      </DisclaimersModal>
    </>
  );
};

export default observer(Content);
