import React from 'react';
import AppStore from './AppStore';

class PostMessageStore {
  currentHeight = 0
  source = null as any
  origin = ''
  url = ''
  isSticky = false

  constructor() {
    window.addEventListener('message', this.receiveMessage);
  }

  sendHeight = () => {
    const height = this.getDocumentHeight();
    const { source, origin } = this;

    // If source exists, send height to parent source
    if (
      source !== null &&
      origin !== null &&
      height !== this.currentHeight
    ){
      window.setTimeout(() => {
        const iframeHeight = this.isSticky ? (AppStore.clientHeight * .79).toString() : height.toString();
        const jsonString = JSON.stringify({iframeHeight});
        source.postMessage(jsonString, origin);
      }, 0);
    }
  }

  scrollTop = () => {
    const { source, origin } = this;
    if (source !== null && origin !== null) { 
      window.setTimeout(() => {
        const jsonString = JSON.stringify({scrollTop: '0'});
        source.postMessage(jsonString, origin);
      }, 0);
    }
  }

  receiveMessage = (event: MessageEvent) => {
    const { sendHeight } = this;
    switch(event.data.method) {
      case 'init': {
        this.url = event.data.url;
        this.source = event.source;
        this.origin = event.origin;
        AppStore.isIframe = true;
        window.onresize = () => {
          sendHeight();
        }
        sendHeight();
        break;
      }
      case 'scroll': {
        AppStore.scrollPosition = event.data.scrollTop;
        break;
      }
      case 'clientHeight': {
        AppStore.clientHeight = event.data.clientHeight;
        break;
      }
    }
  }

  getDocumentHeight = () => {
    const body = document.body, html = document.documentElement;
    return Math.ceil(Math.min(body.offsetHeight, body.scrollHeight, html.offsetHeight)); // Take care of scroll offset
  };
}

export const PostMessageStoreContext = React.createContext({
  postMessageStore: new PostMessageStore()
});

export default PostMessageStore;
