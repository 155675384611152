import React from 'react';
import { MarketingOffer } from '../../types/marketingOffer.models';
import styles from './VehicleDetailsBullets.module.scss';

interface VehicleDetailsBulletsProps {
  marketingOffer: MarketingOffer
}

const VehicleDetailsBullets = ({
  marketingOffer,
}: VehicleDetailsBulletsProps) => {

  return (
    <ul className={styles.bulletsList}>
      { 
        marketingOffer.bullets.bullet.map((bullet, index) => {
        return (
          <li key={index}>
            {!bullet.link && bullet.text}
            {bullet.link && 
              <a 
                href={bullet.link} 
                target='_blank'
                rel="noopener noreferrer"
              >
                {bullet.text}
              </a>
            }
          </li>
        )})
      }
    </ul>
  );
};

export default VehicleDetailsBullets;
