import React from 'react';
import cx from  'classnames';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Container from '../../../src/components/Container';
import Button from '../../../src/components/Button';
import { VehiclesResponseItem } from '../../../src/types/vehiclesResponse.models';
import Modal from '../../../src/components/Modal';
import ContactDealer from '../../../src/components/ContactDealer';

import FinanceInfoOfferDetails from './FinanceInfoOfferDetails';
import styles from './FinanceInfo.module.scss';

interface FinanceInfoProps {
  selectedTrim: VehiclesResponseItem;
  onRaqOpen: (text: string) => void;
}

const FinanceInfo = observer((props: FinanceInfoProps) => {

  const { selectedTrim, onRaqOpen, } = props;
  const { t } = useTranslation();
  const tRequestAQuote = t('common.requestAQuote');

  const [ openContactDealer, setOpenContactDealer ] = React.useState(false);

  const handleOpenRAQ = (text: string) => {
    onRaqOpen(text);
    setOpenContactDealer(true);
  };


  return (
    <Container>
      <div className={styles.financeInfoContainer}>

        {/* Vehicle Section */}
        <section className={styles.vehicleSection}>
          <span
            className={cx({
              [styles.financeLabelLease]: selectedTrim.purchaseType === 'lease',
              [styles.financeLabelFinance]: selectedTrim.purchaseType === 'buy',
            })}
          > 
            {selectedTrim.purchaseType === 'buy' && 'Finance'}
            {selectedTrim.purchaseType === 'lease' && 'Lease'}
          </span>
          <span className={styles.seriesLabel}>
            {ReactHtmlParser(selectedTrim.seriesName ? selectedTrim.seriesName.toUpperCase() : '')}
          </span>
          <span className={styles.seriesGradeLabel}>
            {ReactHtmlParser(selectedTrim.grade || '')}
          </span>
        </section>

        {/* Finance Section */}
        <FinanceInfoOfferDetails selectedTrim={selectedTrim} />

        {/* CTA Section */}
        <section className={styles.ctaSection}>
          <div className={styles.ctaWrapper}>
            <Button
              variant='redBackground'
              onClick={() => handleOpenRAQ(tRequestAQuote)}
            >
              <label htmlFor="perMontfh">{tRequestAQuote}</label>
            </Button>
          </div>
          { (selectedTrim.offer && selectedTrim.offer.offerId) &&
            <p className={styles.date}>
              {t('selectTrim.exp')} <Moment format='MM/DD/YY'>{selectedTrim.offer.endDate}</Moment>
            </p>
          }
        </section>
      </div>

      <Modal
        open={openContactDealer}
        onClose={() => setOpenContactDealer(false)}
      >
        <ContactDealer vehicle={selectedTrim} onClose={() => setOpenContactDealer(false)} />
      </Modal>
    </Container >
  )
});

export default FinanceInfo;
