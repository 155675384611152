import React from 'react';
import styles from './FadeIn.module.scss';

const FadeIn = ({children}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <section className={styles.fadeInContainer}>
      {children}
    </section>
  );
};

export default FadeIn;
