import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import cx from "classnames";
import ReactHtmlParser from "react-html-parser";
import { VehiclesResponseItem } from "../../../../types/vehiclesResponse.models";
import NumberFormat from "../../../../components/NumberFormat";
import styles from "./TrimItem.module.scss";

interface ContentProps extends React.InputHTMLAttributes<HTMLInputElement> {
  trim: VehiclesResponseItem;
  almostInBudget?: boolean;
  isPeLink?: boolean;
}

const Content = observer(
  ({ trim, almostInBudget = false, isPeLink = false }: ContentProps) => {
    const { t } = useTranslation();

    return (
      <>
        <div className={styles.trimWrapper}>
          <label htmlFor="trimName" className={styles.trimName}>
            {ReactHtmlParser(trim.grade || "")}
          </label>
          <span className={styles.content}>
            {!almostInBudget && (
              <label htmlFor="starting">{t("common.startingAt")}</label>
            )}
            <span
              className={cx(styles.optionPayment, {
                [styles.almost]: almostInBudget,
              })}
            >
              {`$${Math.round(trim.payment)}`}
              <label htmlFor="perMonth"> {t("selectTrim.label")}</label>
            </span>

            {/* Offer Details */}
            <section className={styles.offerDetails}>
              <div>
                <div>
                  <span>{trim.term}</span> {t("selectTrim.mos")}
                </div>
              </div>
              {trim.purchaseType === "lease" && (
                <div>
                  <div>
                    <span className={styles.due}>
                      <NumberFormat dollar value={trim.dueAtSigning} />
                    </span>
                    {t("common.dueAtSigning")}
                  </div>
                </div>
              )}
              {(trim.purchaseType === "buy" ||
                trim.purchaseType === "finance") && (
                <>
                  <div>
                    <div>
                      <span>{Number(trim.rate)}%</span> {t("selectTrim.apr")}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>
                        <NumberFormat dollar value={trim.downPayment} />
                      </span>{" "}
                      {t("selectTrim.downPayment")}
                    </div>
                  </div>
                </>
              )}
            </section>
          </span>
        </div>
        <footer className={styles.footer}>
          <div className={styles.cta}>
            {isPeLink
              ? t("vehicleCard.estimatePayment")
              : t("budgetOptions.offerDetails")}
          </div>
        </footer>
      </>
    );
  }
);

export default Content;
