import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import styles from './accordion.module.scss';

interface AccordionSectionProps extends HTMLAttributes<HTMLElement> {
  expand: boolean;
}

const AccordionSection = ({
  expand,
  ...rest
}: AccordionSectionProps) => {
  return (
    <div
      className={cx( styles.accordionSection, expand && styles.expand)}
      {...rest}
    />
  );
};

export default AccordionSection;
