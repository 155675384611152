import React from 'react';
import cx from 'classnames';
import { observer, inject } from 'mobx-react';
import { VehicleCategory } from '../../../../src/types/vehicleCategory.models';
import Button from '../../../../src/components/Button';
import { useTranslation } from 'react-i18next';

import styles from './VehicleCategoryItem.module.scss';

interface VehicleCategoryItemProps extends React.InputHTMLAttributes<HTMLInputElement> {
  vehicleCategory: VehicleCategory;
  onClick: () => void;
}

const VehicleCategoryItem = inject('AppStore')(observer((props: VehicleCategoryItemProps) => {

  const { t } = useTranslation();
  const { vehicleCategory, onClick }  = props;
  
  const handleOnClick = (category: string) => {
    vehicleCategory.selected = !vehicleCategory.selected;
    onClick();
  }

  return (
    <Button 
      className={
        cx(
        styles.vehicleCategoryItem, 
        {
          [styles.categorySelected]: vehicleCategory.selected
        })
      }
      variant="multiSelect"
      fullWidth
      onClick={() => handleOnClick(vehicleCategory.category)}
    >
      <div className={styles.content}>
        <div 
          style={{backgroundImage:`url(${vehicleCategory.image})`}}
          className={
            cx(styles.cars, 
            {
              [styles.carImage]: vehicleCategory.category === 'Cars & Minivan', 
              [styles.truckImage]: vehicleCategory.category === 'Trucks'
            })
          } 
        />
        <label className={styles.label} htmlFor={vehicleCategory.name}>{t(`categories.${vehicleCategory.name}`)}</label>
        <span className={cx(styles.checkbox, {[styles.catSelected]: vehicleCategory.selected})} />
      </div>
    </Button>
  );
}));

export default VehicleCategoryItem;
