import React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';
import { Link } from 'react-router-dom';
import { fireTag } from '../../utils/Analytics';
import getLinkBlock from '../../utils/Analytics/blocks/getLinkBlock';
import { AnalyticsProps } from '../../utils/Analytics/models';

type ButtonVariant = 'redBackground' | 'redArrow' | 'toggleArrow' | 'skip' | 'defaultCta' | 'multiSelect' | 'cancel' | 'default';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, AnalyticsProps {
  variant?: ButtonVariant;
  fullWidth?: boolean;
  disabled?: boolean;
  toggled?: boolean;
  href?: string;
  onClick?: () => void;
  buttonAction?: string;
}

const Button = ({
  variant,
  fullWidth,
  disabled,
  toggled,
  className,
  href,
  children,
  onClick = () => {},
  getBlocks,
  buttonAction,
  linkText,
  ...rest
}: ButtonProps) => {

  const handleOnClick = (innerText: string) => {
    if (getBlocks) {
      const text = linkText ? linkText : innerText; 
      fireTag('aa-link', {
        ...getBlocks(),
        ...getLinkBlock({
          text, 
          typeTitle: text,
          trigger: 'aa-link',
          buttonAction: buttonAction,
          type: 'Button',
        })
      });
    }
    onClick();
  };

  return (
    <>
      { !href && 
        <button
          disabled={disabled}
          className={
            cx([
            className,
            {
              [styles.button]: true,
              [styles.defaultCta]: variant === 'redBackground',
              [styles.redArrow]: variant === 'redArrow',
              [styles.toggleArrow]: variant === 'toggleArrow',
              [styles.toggled]: toggled,
              [styles.fullWidth]: fullWidth,
              [styles.default]: variant === 'default'
            }])
          }
          onClick={e => handleOnClick(e.currentTarget.innerText)}
          {...rest}
        >
          { children }
        </button>
      }
      {
        href &&
        <Link
          to={href}
          className={
            cx([
            className,
            {
              [styles.button]: true,
              [styles.defaultCta]: variant === 'redBackground',
              [styles.redArrow]: variant === 'redArrow',
              [styles.toggleArrow]: variant === 'toggleArrow',
              [styles.toggled]: toggled,
              [styles.fullWidth]: fullWidth
            }])
          }
          onClick={e => handleOnClick(e.currentTarget.innerText)}
        >
          {children}
        </Link>
      }
    </>
  );
};

export default Button;
