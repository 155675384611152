import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AppStoreContext } from '../../../stores/AppStore';
import { PostMessageStoreContext } from '../../../stores/PostMessageStore';
import { getBudgetArrays } from '../../../utils/budgetUtils';
import { fireTag } from '../../../utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getOfferListBlock from '../../../utils/Analytics/blocks/getOfferListBlock';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import { getOfferBlockFromArray } from '../../../utils/Analytics/blocks/getOfferBlock';
import BudgetOptionsHeader from './BudgetOptionsHeader';
import Container from '../../../components/Container';
import FadeIn from '../../../components/FadeIn';
import BudgetOptionItem from './BudgetOptionItem';
import Button from '../../../components/Button';
import BudgetSlider from '../../../components/Slider';
import useChecklist from '../../../hooks/useChecklist';
import styles from './BudgetOptions.module.scss';

const contentSection = 'wfmb_offer_results';

const BudgetOptions = () => {
  const { t } = useTranslation();
  const {
    AppStore: {
      setSeriesOptions,
      currentBudgetValueMax,
      min,
      max,
      toggleSlider,
      setToggleSlider,
      vehiclesList,
      nextMobileState,
      getVehicles,
      seriesData
    },
  } = React.useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight, scrollTop}
  } = React.useContext(PostMessageStoreContext);

  const { checklist, setChecklist } = useChecklist([]);
  const [ isKeyMetricLoaded, setIsKeyMetricLoaded ] = React.useState(false);
  const [ currentBudget, setCurrentBudget ] = React.useState(currentBudgetValueMax);
  const [ almostInBudgetLength, setAlmostInBudgetLength ] = React.useState(0);

  useEffect(() => {

    const { inBudgetOffers, almostInBudgetOffers } = getBudgetArrays(vehiclesList, seriesData);
    inBudgetOffers.sort((a,b) => a.payment - b.payment);
    almostInBudgetOffers.sort((a,b) => a.payment - b.payment);
    const offers = inBudgetOffers.concat(almostInBudgetOffers);
    setAlmostInBudgetLength(almostInBudgetOffers.length);
    setChecklist(offers);
    setIsKeyMetricLoaded(true);

    window.setTimeout(() => {
      sendHeight();
      scrollTop();
    }, 100)

  }, [setChecklist, vehiclesList, setIsKeyMetricLoaded, sendHeight, scrollTop, seriesData]);

  const taggingBlocks = () => ({
    ...getGlobalBlock(),
    ...getPageBlock(contentSection),
    ...getOfferListBlock({layout: 'list'}),
  });

  useEffect(() => {
    if (isKeyMetricLoaded) {
      fireTag(
        'ready',
        {
          metrics: 'km-offers-search,aa-pageload',
          ...getGlobalBlock(),
          ...getPageBlock(contentSection),
          ...getOfferListBlock({layout: 'list'}),
        }
      ); 
    }
  }, [isKeyMetricLoaded]);

  const handleOnCancel = () => {
    updateToggleSlider();
  };

  const updateToggleSlider = () => {
    checklist.forEach((item) => {
      item.selected = false
    });
    setToggleSlider(false);
  }

  const selectedOptions = checklist.filter((item) =>
    item.selected
  );
  if(selectedOptions.length) {
    setToggleSlider(true);
  }

  const handleOnViewDetail = (text: string) => {
    setSeriesOptions([...checklist]);
    nextMobileState();
  };

  const handleOnSliderChange = async (value: number) => {
    const aaLinkText = value ? value.toString() : undefined;
    fireTag('aa-link', {
      ...taggingBlocks(),
      ...getLinkBlock({
        action: 'filter price',
        text: aaLinkText,
        trigger: 'aa-link',
        typeClick: 'price-slider',
        typeTitle: 'Price',
      }),
    });

    const count = vehiclesList.length;
    const newVehiclesList = await getVehicles();
    const newCount = newVehiclesList.length;
    if (count !== newCount) {
      fireTag( 'aa-pageload', { ...taggingBlocks() }); 
    }
  };

  return (
    <>
      <BudgetOptionsHeader numOfVehicles={checklist.length} contentSection={contentSection} />
      <div className={styles.budgetOptionsWrapper}>
        <Container className={styles.catContainer}>
          <FadeIn>
            {/* In Your Budget */}
            {
              checklist.map((option, index) => 
                (option.payment <= currentBudget) ?
                <BudgetOptionItem
                  key={`${option.series}${option.model}`}
                  almostInBudget={false}
                  index={index}
                  budgetOption={option}
                  onClick={() => {
                    setChecklist([...checklist]);
                    setSeriesOptions([...checklist]);
                  }}
                /> : (null)
              )
            }
            {/* Almost In Your Budget */}
            {
              almostInBudgetLength > 0 &&
              <div className={styles.labelContainer}>
                <label htmlFor="almostInBudget" >
                  {t('budgetOptions.almostInBudget')}
                </label>
              </div>
            }
            {
              checklist.map((option, index) => 
                (option.payment > currentBudget) ?
                <BudgetOptionItem
                  key={option.model}
                  almostInBudget={true}
                  index={index}
                  budgetOption={option}
                  onClick={() => {
                    setChecklist([...checklist]);
                    setSeriesOptions([...checklist]);
                  }}
                /> : (null)
              )
            }
          </FadeIn>
        </Container>
        <FadeIn>
          <section className={styles.footer}>
            {
              selectedOptions.length && toggleSlider ? (
                <div className={styles.option1}>
                  <Container>
                    <Button
                      className={styles.cancelCta}
                      variant='cancel'
                      onClick={handleOnCancel}
                      buttonAction='Cancel'
                      getBlocks={() => taggingBlocks()}
                    >
                      {t('budgetOptions.cancelCta')}
                    </Button>
                    <Button
                      className={styles.ctaContainer}
                      variant='redBackground'
                      onClick={() => handleOnViewDetail(`${t('budgetOptions.ctaLabel')} (${selectedOptions.length})`)}
                      disabled={selectedOptions.length === 0}
                      buttonAction='Cancel'
                      getBlocks={() => ({
                        ...taggingBlocks(),
                        ...getOfferBlockFromArray(checklist.filter(item => item.selected))
                      })}
                    >
                      {t('budgetOptions.ctaLabel')} ({selectedOptions.length})
                    </Button>
                  </Container>
                </div>
              ) : (
                <div className={styles.option2}>
                  <Container>
                    <div className={styles.sliderWrapper}>
                      <BudgetSlider
                        min={min}
                        max={max}
                        hideToolTip={true}
                        onChange={value => setCurrentBudget(value)}
                        onAfterChange={handleOnSliderChange}
                      />
                      <span className={styles.budgetValue}>{`${currentBudget}`}</span>
                      <label htmlFor="budget" className={styles.perMonthLabel}>
                        {t('budgetOptions.label')}
                      </label>
                    </div>
                  </Container>
                </div>
              )
            }
          </section>
        </FadeIn>
      </div>
    </>
  );
};

export default observer(BudgetOptions);
