import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../BudgetDesktop.module.scss';
import BudgetRange from '../../../components/Slider/BudgetRange';
import Input from '../../../components/Input';

interface SliderProps {
  min: number,
  max: number,
  onInputMinChange: (value: string) => void;
  onInputMaxChange: (value: string) => void;
  onSliderChange: (min: number, max: number) => void;
}

const Slider = ({
  min,
  max,
  onInputMinChange,
  onInputMaxChange,
  onSliderChange,
}: SliderProps) => {

  const [ budgetMin, setBudgetMin ] = useState(min);
  const [ budgetMax, setBudgetMax ] = useState(max);
  const { t } = useTranslation();

  return (
    <section className={styles.sliderSection}>
      <label className={styles.sliderLabel}>
        <Input
          number
          inputValue={budgetMin}
          aria-controls="minPerMonth"
          onInputChange={val => {
            setBudgetMin(Number(val))
            onInputMinChange(val);
          }}
        />
        {t('budgetInterstitial.min')}
      </label>
      <div className={styles.sliderContainer}>
        <BudgetRange
          onChange={(min, max) => {
            setBudgetMin(min);
            setBudgetMax(max);
          }}
          onAfterChange={onSliderChange} 
        />
      </div>
      <label className={styles.sliderLabel}>
        <Input
          number
          inputValue={budgetMax}
          aria-controls="maxPerMonth"
          onInputChange={val => {
            setBudgetMax(Number(val));
            onInputMaxChange(val);
          }}
        />
        {t('budgetInterstitial.max')}
      </label>
    </section>
  );
};

export default Slider;
