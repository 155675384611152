import { VehiclesResponseItem } from "../../../types/vehiclesResponse.models";
import getOfferVehicleBlock from "./getOfferVehicleBlock";

interface OfferBlock {
  category: string;
  type: string;
  id?: string;
  expiredDate?: string;
  term: string;
  rate?: string;
  amountDue?: string;
  monthly: string;
  cashBack?: string;
  title?: string;
  label?: string;
}

const getOfferBlock = (offer: OfferBlock) => ({
  offer_category: offer.category,
  offer_type: offer.type,
  offer_id: offer.id,
  offer_expired_date: offer.expiredDate,
  offer_term: offer.term,
  offer_rate: offer.rate,
  offer_amtdue: offer.amountDue,
  offer_monthly: offer.monthly,
  offer_cashback: offer.cashBack,
  offer_title: offer.title,
  offer_label: offer.label,
});

export const getOfferBlockFromResponse = (response: VehiclesResponseItem) => {
  const {
    purchaseType,
    dueAtSigning,
    term,
    payment,
    offer,
    model,
    grade,
    category,
    series,
    year,
    rate,
  } = response;

  return {
    ...getOfferBlock({
      category: 'Vehicle',
      type: purchaseType,
      id: offer ? offer.offerId : undefined,
      expiredDate: offer ? offer.endDate : undefined,
      term: term ? term.toString() : '',
      rate: rate ? rate.toString() : '',
      amountDue: dueAtSigning ? dueAtSigning.toString() : undefined,
      monthly: payment ? payment.toString() : '',
      // cashBack: string;
    }),
    ...getOfferVehicleBlock({
      number: model,
      grade: grade,
      category: category,
      code: series,
      year: year,
    }, true)
  };  
};

export const getOfferBlockFromArray = (responses: VehiclesResponseItem[]) => {
  const purchaseType = responses.map(item => item.purchaseType);
  const offerId = responses.map(item => item.offer ? item.offer.offerId : '');
  const endDate = responses.map(item => item.offer ? item.offer.endDate : '');
  const term = responses.map(item => item.term);
  const rate = responses.map(item => item.rate);
  const amountDue = responses.map(item => item.dueAtSigning);
  const payment = responses.map(item => item.payment); 

  const model = responses.map(item => item.model);
  const grade = responses.map(item => item.grade);
  const category = responses.map(item => item.category);
  const series = responses.map(item => item.series);
  const year = responses.map(item => item.year); 

  return {
    ...getOfferBlock({
      category: 'Vehicle',
      type: purchaseType.join(','),
      id: offerId.join(','),
      expiredDate: endDate.join(','),
      term: term.join(','),
      rate: rate.join(','),
      amountDue: amountDue.join(','),
      monthly: payment.join(','),
    }),
    ...getOfferVehicleBlock({
      number: model.join(','),
      grade: grade.join(','),
      category: category.join(','),
      code: series.join(','),
      year: year.join(','),
    }, true)  
  }

};

export default getOfferBlock;
