import React from 'react';
import { observer } from 'mobx-react';

import { AppStoreContext } from '../../../stores/AppStore';
import { PostMessageStoreContext } from '../../../stores/PostMessageStore';
import { useTranslation } from 'react-i18next';
import { fireTag } from '../../../utils/Analytics';
import getGlobalBlock from '../../../utils/Analytics/blocks/getGlobalBlock';
import getPageBlock from '../../../utils/Analytics/blocks/getPageBlock';
import getOfferListBlock from '../../../utils/Analytics/blocks/getOfferListBlock';
import getLinkBlock from '../../../utils/Analytics/blocks/getLinkBlock';
import Header from '../../../components/Header';
import Tabs from '../../../components/Tabs';
import FadeIn from '../../../components/FadeIn';
import Container from '../../../components/Container';
import VehicleCategory from '../../../components/VehicleCategory';
import getListItem from '../../../utils/Analytics/blocks/getListItem';
import Button from '../../../components/Button';
import styles from './VehicleCategory.module.scss';
import useMobileDetect from '../../../../src/hooks/useMobileDetect';

const VehicleCategories = () => {

  const {
    AppStore: {
      nextMobileState,
      setFilters,
      categories,
      categoryFiltersAvailable
  }} = React.useContext(AppStoreContext);

  const {
    postMessageStore: { sendHeight, scrollTop }
  } = React.useContext(PostMessageStoreContext);
  const { isMobile } = useMobileDetect();

  const contentSection = 'wfmb_step2_vehicle_filter'; 
  const { t } = useTranslation();

  React.useEffect(() => {
    sendHeight();
    scrollTop();
    setFilters(isMobile);
    fireTag(
      'ready',
      {
        metrics: 'aa-pageload',
        ...getGlobalBlock(),
        ...getPageBlock(contentSection),
        ...getOfferListBlock(),
      }
    );
  }, [sendHeight, scrollTop, setFilters, isMobile]);

  const handleNextButton = (text: string) => {
    fireTag('aa-link', {
      ...getGlobalBlock(),
      ...getPageBlock(contentSection),
      ...getLinkBlock({
        text, 
        typeTitle: text,
        trigger: 'aa-link',
        type: 'Button',
      }),
      ...getOfferListBlock(),
    });
    nextMobileState();
  };

  return (
    <>
      <Header isMobileWorkflow />
      <Tabs />
      <FadeIn>
        <Container className={styles.catContainer}>
          <VehicleCategory onClick={(text, index) => {
            fireTag('aa-link',
            {
              ...getPageBlock(contentSection),
              ...getLinkBlock({
                url: '/',
                href: '/',
                text: text, 
                typeTitle: text,
                trigger: 'aa-link',
                buttonAction: 'Select',
                type: 'Button'
              }),
              ...getListItem(index),
              ...getOfferListBlock({})
            } 
          );
          }} />
          <div className={styles.ctaContainer}>
            <Button
              className={styles.nextCta}
              variant='redBackground'
              fullWidth
              onClick={() => handleNextButton(t('vehicleCategory.next')) }
              disabled={!categories.filter(item => item.selected).length && !categoryFiltersAvailable}
            >
              {t('vehicleCategory.next')}
            </Button>
          </div>
        </Container>
      </FadeIn>
    </>
  );
};

export default observer(VehicleCategories);
