import cx from 'classnames';
import React, { useRef } from 'react';
import styles from './Checkbox.module.scss';
import { AnalyticsProps } from '../../utils/Analytics/models';
import getLinkBlock from '../../utils/Analytics/blocks/getLinkBlock';
import { fireTag } from '../../utils/Analytics';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement>, AnalyticsProps {
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
  id?: string;
  round?: boolean;
}

const Checkbox = ({ className, checked, onChange, onChecked = () => {}, children, id, round, getBlocks, ...rest }: CheckboxProps) => {
  const [isChecked, setIsChecked] = React.useState(checked);
  const ref = useRef<HTMLLabelElement>(null);

  return (
    <div className={cx([className, styles.checkbox])}>
      <input
        id={id}
        type='checkbox'
        className={cx({
          [styles.input]: true,
          [styles.round]: round,
        })}
        checked={isChecked}
        onChange={e => {
          setIsChecked(e.currentTarget.checked);
          onChecked(e.currentTarget.checked);
          if (getBlocks) {
            fireTag('aa-link', {
              ...getBlocks(),
              ...getLinkBlock({
                text: ref.current?.innerText,
                typeTitle: ref.current?.innerText,
                trigger: 'aa-link',
                buttonAction: 'Check',
                type: 'Checkbox',
              }),
            });
          }
        }}
        {...rest}
      />
      <label ref={ref} htmlFor={id} className={styles.label}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
