import { RequestAQuoteRequest, slpData } from "../types/requestAQuote.models";
import { VehiclesResponseItem } from "../types/vehiclesResponse.models";
import cookie from "js-cookie";
import { createRandomIdentifier } from "../components/ContactDealer/Success";

const createJSONFromForm = (
  zip: string,
  request: RequestAQuoteRequest,
  vehicle: VehiclesResponseItem,
  domain: string
) => {
  const {
    firstname,
    lastname,
    dealer,
    comments = "",
    preference,
    email,
    phone,
    isMobile,
    campaignCode,
    purchaseType,
  } = request;

  const format = isMobile ? "Mobile" : "Website";
  const omnitureID = createRandomIdentifier();
  const requestDate = new Date();
  const paymentItems = `Estimated Payment:\n $${vehicle.payment}/mo | ${vehicle.term} months\n Zip code:${zip}`;
  const type = purchaseType || vehicle.purchaseType;
  const convertSpecialCharacters = (field: string) => {
    field = field.replace(/&/g, "&#38;");
    field = field.replace(/"/g, "&#34;");
    field = field.replace(/'/g, "&#39;");
    field = field.replace(/</g, "&#60;");
    field = field.replace(/>/g, "&#62;");

    return field;
  };

  const convertedFirstName = convertSpecialCharacters(firstname);
  const convertedLastName = convertSpecialCharacters(lastname);
  const convertedEmail = convertSpecialCharacters(email);
  const convertedComments = convertSpecialCharacters(comments);
  const commentRequest = `${paymentItems} ${convertedComments}` || "";
  const zipCodeAccuracy = cookie.get("zipcode_accuracy");

  let json: slpData = {
    adf: {
      prospect: {
        id: {
          value: "-2",
          sequence: "1",
          source: "Toyota",
        },
        requestdate: requestDate,
        vehicle: {
          interest: type,
          status: "new",
          year: vehicle.year,
          make: "Toyota",
          model: vehicle.series,
          trim: vehicle.model,
        },
        customer: {
          contact: {
            name: [
              {
                value: convertedFirstName,
                part: "first",
                type: "individual",
              },
              {
                value: convertedLastName,
                part: "last",
                type: "individual",
              },
            ],
          },
          comments: commentRequest,
        },
        vendor: {
          id: {
            value: dealer.code,
            sequence: "1",
            source: "TMS Dealer Code",
          },
          vendorname: dealer.name,
          url: dealer.contact.website,
          name: [
            {
              value: dealer.contact.firstName,
              part: "first",
              type: "individual",
            },
            {
              value: dealer.contact.lastName,
              part: "last",
              type: "individual",
            },
          ],
          email: {
            value: dealer.contact.email,
            preferredcontact: "0",
          },
          phone: {
            value: dealer.contact.phone,
            preferredcontact: "1",
            type: "voice",
            time: "nopreference",
          },
          address: {
            street: {
              line: "1",
              value: dealer.contact.street,
            },
            city: dealer.contact.city,
            regioncode: dealer.contact.region,
            postalcode: dealer.contact.zip,
          },
        },
        provider: {
          id: [
            {
              sequence: "1",
              source: "Toyota",
            },
            {
              value: omnitureID,
              source: "Omniture_ID",
            },
          ],
          name: format,
          service: campaignCode,
        },
      },
    },
  };

  if (zipCodeAccuracy) {
    json.adf.prospect.provider.id.push({
      value: zipCodeAccuracy,
      source: "Zipcode_Accuracy",
    });
  }

  if (preference === "email") {
    json.adf.prospect.customer.contact.email = {
      value: convertedEmail,
      preferredcontact: "0",
    };
  } else if (preference === "phone") {
    json.adf.prospect.customer.contact.phone = {
      value: phone,
      preferredcontact: "1",
    };
  }

  return json;
};

export { createJSONFromForm };
