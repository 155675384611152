import React from 'react';
import styles from './FinanceTypeItem.module.scss';
import cx from 'classnames';

interface FinanceTypeItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const FinanceTypeItem = (props: FinanceTypeItemProps) => {

  const { label, selected, ...rest }  = props;

  return (
    <button
      className={cx(styles.tile, {[styles.financeTile]: selected})} 
      {...rest}
    >
      <span className={styles.financeLabel}>{label}</span>
    </button>
  );
};

export default FinanceTypeItem;
