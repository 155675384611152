type TagType =
  | 'aa-component-view'
  | 'aa-link'
  | 'aa-pageload'
  | 'aa-subpageload'
  | 'km-pageload'
  | 'km-link'
  | 'km-link-1'
  | 'km-link-2'
  | 'km-pe-shown'
  | 'km-pe-start'
  | 'km-pe-complete'
  | 'km-cad-shown'
  | 'km-cad-start'
  | 'km-cad-complete'
  | 'km-offers-detail'
  | 'km-offers-search'
  | 'ready';

const fireTag = (tag: TagType, blocks: any) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof window.fireTag === 'function') {
    window.fireTag(tag, blocks);
  } else {
    console.log('Analytics', tag);
    for (var prop in blocks) {
      if (blocks[prop] === undefined) {
        delete blocks[prop];
      }
    }
    console.table(blocks);
  }
};

export { fireTag };
