import React from 'react';
import styles from '../../VehicleCard.module.scss';
import { VehiclesResponseItem } from '../../../../../../types/vehiclesResponse.models';

interface CarJellyImageProps {
  offer: VehiclesResponseItem;
}

const CarJellyImage = ({
  offer
}: CarJellyImageProps) => {
  return (
    <div className={styles.carJelly}>
      <img src={offer.carJellyImage} alt={offer.carJellyImage} />
    </div>
  );
};

export default CarJellyImage;
