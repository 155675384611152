import AppStore from "../../../stores/AppStore";

const getBreakpoint = () => {
  const breakpoint = '768';
  let deviceType = 'Desktop';

  if (typeof window !== 'undefined') {
    const { innerWidth } = window;
    if (innerWidth < 768) {
      deviceType = 'Mobile';
    }
  }

  return {
    device_type: deviceType,
    breakpoint: breakpoint,
  }
};

const getGlobalBlock = () => {

  const { tdaCode, zip } = AppStore;

  return {
    ...getBreakpoint(),
    tda_code: tdaCode,
    zipcode: zip,
  };
};

export default getGlobalBlock;
