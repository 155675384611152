import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import Disclaimers from '../../../../../../components/Disclaimers';
import DisclaimersModal from '../../../../../../components/DisclaimersModal';
import NumberFormat from '../../../../../../components/NumberFormat';
import { VehiclesResponseItem } from '../../../../../../types/vehiclesResponse.models';
import { fireTag } from '../../../../../../utils/Analytics';
import getLinkBlock from '../../../../../../utils/Analytics/blocks/getLinkBlock';
import styles from '../../VehicleCard.module.scss';

interface HeaderProps {
  offer: VehiclesResponseItem;
  taggingBlocks: any;
}

const Header = ({
  offer,
  taggingBlocks,
}: HeaderProps) => {

  const [ openDisclaimer, setOpenDisclaimer ] = useState(false);
  const { t } = useTranslation();
  const tDisclaimers = [
    t('disclaimers.estimateIncludes'),
    t('disclaimers.estimatePayments'),
    t('disclaimers.estimateCredit'),
    t('disclaimers.estimateQualify')
  ].join(' ');

  const handleOpenDisclaimer = (text: string) => {
    fireTag('aa-link', {
      ...taggingBlocks,
      ...getLinkBlock({
        text,
        typeTitle: text,
        trigger: 'aa-link',
        buttonAction: 'Open Modal',
        type: 'Button'
      }),
    });
    setOpenDisclaimer(true);
  };

  return (
    <>
      <header className={styles.header}>
        <div>
          {/* Payment */}
          <div className={styles.startingAt}> {t('common.startingAt')}</div>
          <span className={styles.payment}>$<NumberFormat value={Math.round(offer.payment)} /></span>
          {/* Per Mo Label */}
          <span className={styles.months}>
            {t('vehicleDetails.perMonthDesktop')}
            <sup className={styles.disclaimer} onClick={() => handleOpenDisclaimer('1')}> 1</sup>
          </span>
        </div>
        <div>
          {/* Expiration */}
          <span
            className={cx({
              [styles.expiration]: true,
              [styles.hide]: !offer.offer || !offer.offer.offerId
            })}
          >
            {t('vehicleDetails.expire')} <Moment format='MM/DD/YY'>{offer.offer && offer.offer.endDate}</Moment>
          </span>
        </div>
      </header>
      <DisclaimersModal
        open={openDisclaimer}
        onClose={() => setOpenDisclaimer(false)}
      >
        <Disclaimers onClose={() => setOpenDisclaimer(false)}>
          { tDisclaimers }
        </Disclaimers>
      </DisclaimersModal>
    </>
  );
};

export default Header;
