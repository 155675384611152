import React, { HTMLAttributes } from 'react';
import styles from './Container.module.scss';
import cx from 'classnames';

const Container = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {

  return (
    <section className={cx([className, styles.container])} {...rest} />
  );
};

export default Container;
