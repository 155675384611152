import React from 'react';
import { observable } from 'mobx';
import { AxiosPromise } from 'axios';
import { disZipXForm, disDealerXForm, vehiclesXForm } from './xforms';
import { VehiclesResponse, VehiclesResponseItem } from '../types/vehiclesResponse.models';
import { VehicleCategory } from '../types/vehicleCategory.models';
import { EFCMSResponse, EFCMSColorResponse, EFCMSSeriesResponse } from '../types/efcMs.models';
import { RequestAQuoteRequest } from '../types/requestAQuote.models';
import { Dealer } from '../types/dealer.models';
import { BudgetOption } from '../types/budgetOption.models';
import { MarketingOffersResponse } from '../types/marketingOffer.models';
import { getDealers } from '../webservices/dis.api';
import { getSeries, getTrims, getColors } from '../webservices/efc.api';
import { getVehicles } from '../webservices/wfmb.api';
import { createJSONFromForm } from '../utils/slpUtils';
import { submitSLPLead } from '../webservices/slp.api';
import { getMarketingData } from '../webservices/oatms.api';
import { ZipInfoType } from '../types/zip.models';
import vehicleCategories from '../components/VehicleCategory/vehicleCategories.json';
import { refactorSeries } from '../utils/refactorSeries';

export type MobileState = 'budget' | 'budgetOptions' | 'finance' | 'selectTrim' | 'vehicleCategory';
const mobileStateOrder = ['finance', 'vehicleCategory', 'budget', 'budgetOptions', 'selectTrim'] as MobileState[];

export type Domain = 'toyota' | 'buyatoyota';
export interface IAppStore {
  scrollPosition: number;
  clientHeight: number;
  isIframe: boolean;
  currentState: MobileState;
  toggleSlider: boolean;
  selectedSeries: VehiclesResponse;
  selectedTrim: VehiclesResponseItem;
  selectedTrims: VehiclesResponseItem[];
  financeTypes: string[];
  categories: VehicleCategory[];
  vehiclesList: VehiclesResponse[];
  filteredVehiclesList: VehiclesResponseItem[];
  selectedVehicles: VehiclesResponse[];
  efcData: EFCMSResponse;
  seriesData: EFCMSSeriesResponse;
  trimsData: EFCMSResponse;
  tdaCode: string;
  state: string;
  regionCode: string;
  pricingArea: string;
  purchaseType: string;
  series: string;
  min: number;
  max: number;
  currentBudgetValueMin: number;
  currentBudgetValueMax: number;
  seriesOptions: VehiclesResponse[];
  zip: string;
  brand: string;
  brandId: string;
  dealersInfo: any[];
  showOffersDesktop: boolean;
  campaignCode: string;
  language: string;
  isFinanceChecked: boolean;
  isLeaseChecked: boolean;
  categoryFiltersAvailable: boolean;
  filters: string;
  domain: Domain;
  xact: string;
  assignCampaignCode: (code: string) => void;
  setShowOffersDesktop: (show: boolean) => void;
  submitRAQ: (request: RequestAQuoteRequest) => AxiosPromise;
  setSelectedSeries: (series: VehiclesResponse) => void;
  setSelectedTrim: (trim: VehiclesResponseItem) => void;
  setSelectedTrims: (trims: VehiclesResponseItem[]) => void;
  setToggleSlider: (value: boolean) => void;
  setFilteredVehicles: () => void;
  setFinanceType: (buySelected: boolean, leaseSelected: boolean) => void;
  setCurrentState: (type: MobileState) => void;
  nextMobileState: () => void;
  previousMobileState: () => void;
  setCategories: (categories: VehicleCategory[]) => void;
  getZipInfo: (zip: string) => Promise<ZipInfoType>;
  getEfcData: () => AxiosPromise;
  getEFCColor: (year: string, series: string) => AxiosPromise<EFCMSColorResponse>;
  getVehicles: () => Promise<VehiclesResponse[]>;
  getDealers: (zip: string) => Promise<Dealer[]>;
  setBudgetValueMin: (value: number) => void;
  setBudgetValueMax: (value: number) => void;
  setSeriesOptions: (seriesOptions: BudgetOption[]) => void;
  getMarketingData: (offerId: string) => AxiosPromise<MarketingOffersResponse[]>;
  setLanguage: (langParam: string) => void;
  setFilters: (mobile: boolean) => void;
  setIsFinanceChecked: (finance: boolean) => void;
  setIsLeaseChecked: (lease: boolean) => void;
  setDomain: () => void;
}

const AppStore: IAppStore = observable({
  campaignCode: '',
  assignCampaignCode: code => {
    AppStore.campaignCode = code;
  },
  scrollPosition: 0,
  clientHeight: 0,
  isIframe: false,
  financeTypes: [],
  setFinanceType: (buySelected: boolean, leaseSelected: boolean) => {
    const types = [];
    if (buySelected) {
      types.push('buy');
    }
    if (leaseSelected) {
      types.push('lease');
    }
    AppStore.financeTypes = types;
  },
  currentState: 'finance',
  setCurrentState: type => (AppStore.currentState = type),
  nextMobileState: () => {
    let currentIndex = mobileStateOrder.indexOf(AppStore.currentState);
    currentIndex++;
    const state = mobileStateOrder[currentIndex];
    if (state) {
      AppStore.setCurrentState(state);
    }
  },
  previousMobileState: () => {
    let currentIndex = mobileStateOrder.indexOf(AppStore.currentState);
    currentIndex--;
    const state = mobileStateOrder[currentIndex];
    if (state) {
      AppStore.setCurrentState(state);
    }
  },
  categories: [] as VehicleCategory[],
  setCategories: (categories: VehicleCategory[]) => {
    AppStore.categories = [...categories];
  },
  regionCode: '110',
  tdaCode: 'SOC10',
  state: '',
  pricingArea: '',
  purchaseType: '',
  series: '',
  min: 0,
  max: 750,
  currentBudgetValueMin: 0,
  currentBudgetValueMax: 750,
  vehiclesList: [],
  filteredVehiclesList: [],
  dealersInfo: [],
  efcData: { years_list: [] },
  seriesData: { series_data: [] },
  trimsData: { years_list: [] },
  zip: '92614',
  brand: 'Toyota',
  brandId: '1',
  xact: '',
  showOffersDesktop: false,
  setShowOffersDesktop: show => {
    AppStore.showOffersDesktop = show;
  },
  getZipInfo: async zip => {
    const zipCode = zip ? zip : AppStore.zip;
    const disResponse = await getDealers({
      zipcode: zipCode,
      brandId: AppStore.brandId,
      resultsMax: 1,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'full',
    });

    const { tdaCode, state, pricingArea, regionCode } = disZipXForm(disResponse.data);

    AppStore.tdaCode = tdaCode;
    AppStore.state = state;
    AppStore.pricingArea = pricingArea;
    AppStore.regionCode = regionCode;
    AppStore.zip = zipCode;

    return {
      tdaCode,
      state,
      pricingArea,
      regionCode,
      zip: zipCode,
    };
  },
  getEfcData: async () => {
    const year = new Date().getFullYear();
    const years = [year - 2, year - 1, year, year + 1].join(',');
    const regionCode = AppStore.regionCode;
    const lang = 'EN';

    const efcMsSeriesResponse = await getSeries({ regionCode, years, lang });
    const efcMsTrimResponse = await getTrims({ regionCode, years, lang });

    refactorSeries(efcMsSeriesResponse.data as EFCMSSeriesResponse);

    AppStore.seriesData = efcMsSeriesResponse.data as EFCMSSeriesResponse;
    AppStore.trimsData = efcMsTrimResponse.data as EFCMSResponse;

    AppStore.efcData = AppStore.trimsData;
    return efcMsTrimResponse;
  },
  getEFCColor: (year: string, series: string) => {
    const regionCode = AppStore.regionCode;
    return getColors({ regionCode, year, series });
  },
  getVehicles: async () => {
    if (!AppStore.efcData.years_list.length) {
      await AppStore.getEfcData();
    }

    AppStore.purchaseType = AppStore.financeTypes.map(type => type.toLowerCase()).join(','); // eg '', 'buy,lease', 'buy'

    AppStore.series = AppStore.categories
      .filter(cat => cat.selected)
      .reduce((seriesList, cat) => [...seriesList, ...cat.seriesList], [] as string[])
      .join(',');

    // Send a GET request to some REST api
    const tdaCode = AppStore.tdaCode.replace('10', '');
    const { state, pricingArea, purchaseType, currentBudgetValueMin, currentBudgetValueMax, series } = AppStore;
    const minBudget = currentBudgetValueMin.toString();
    const maxBudget = currentBudgetValueMax.toString();

    let vehicleList = [] as VehiclesResponse[];
    const apiResponse = await getVehicles({ tdaCode, state, pricingArea, purchaseType, minBudget, maxBudget, series });
    if (apiResponse.data.length) {
      vehicleList = vehiclesXForm(apiResponse, AppStore.efcData, AppStore.currentBudgetValueMax);
    }

    AppStore.vehiclesList = vehicleList;
    return vehicleList;
  },
  setFilteredVehicles: () => {
    let vehicleList = [] as VehiclesResponseItem[];
    AppStore.vehiclesList.forEach(vehicle => {
      vehicle.inBudget.forEach(item => (item.series = vehicle.series));
      vehicleList = [...vehicleList, ...vehicle.inBudget];
    });
    AppStore.filteredVehiclesList = vehicleList;
  },
  setBudgetValueMin: value => (AppStore.currentBudgetValueMin = value),
  setBudgetValueMax: value => (AppStore.currentBudgetValueMax = value),
  selectedVehicles: [],
  seriesOptions: [] as BudgetOption[],
  setSeriesOptions: (seriesOptions: BudgetOption[]) => {
    const selectedSeries = seriesOptions.filter(option => option.selected).map(option => option.series);

    const vehiclesList = AppStore.vehiclesList.filter(item => selectedSeries.indexOf(item.series) > -1);

    AppStore.seriesOptions = vehiclesList;
  },
  toggleSlider: true,
  setToggleSlider: value => (AppStore.toggleSlider = value),
  selectedSeries: {} as VehiclesResponse,
  setSelectedSeries: (type: VehiclesResponse) => (AppStore.selectedSeries = type),
  selectedTrim: {} as VehiclesResponseItem,
  selectedTrims: [] as VehiclesResponseItem[],
  setSelectedTrim: (type: VehiclesResponseItem) => (AppStore.selectedTrim = type),
  setSelectedTrims: trims => (AppStore.selectedTrims = trims),

  getDealers: async (zipCode: string) => {
    const gNum = 25;
    const pmaResp = await getDealers({
      zipcode: zipCode,
      brandId: AppStore.brandId,
      resultsMax: gNum,
      searchMode: 'pmaProximityLayered',
      resultsMode: 'starOnly',
      proximityMode: 'expandingRadius',
    });

    const proximityResp = await getDealers({
      zipcode: zipCode,
      brandId: AppStore.brandId,
      resultsMax: gNum,
      searchMode: 'proximityOnly',
      resultsMode: 'starOnly',
      proximityMode: 'expandingRadius',
    });

    return disDealerXForm(pmaResp.data, proximityResp.data, AppStore.tdaCode);
  },
  submitRAQ: (request: RequestAQuoteRequest) => {
    const { zip, selectedTrim } = AppStore;
    const domain = AppStore.domain;
    const json = createJSONFromForm(zip, request, selectedTrim, domain);
    return submitSLPLead(json);
  },
  getMarketingData: async (offerId: string) => {
    const lang = AppStore.language;
    const domain = AppStore.domain;
    return getMarketingData({ zipCode: AppStore.zip, offerId, lang, domain });
  },
  language: 'en',
  setLanguage: (langParam: string) => {
    const lang = langParam ? langParam : AppStore.language;
    AppStore.language = lang;
  },
  filters: '',
  isFinanceChecked: false,
  isLeaseChecked: false,
  setIsFinanceChecked: (finance: boolean) => (AppStore.isFinanceChecked = finance),
  setIsLeaseChecked: (lease: boolean) => (AppStore.isLeaseChecked = lease),
  categoryFiltersAvailable: false,
  setFilters: (mobile: boolean) => {
    const searchFilters = window.location.search.split('&');
    const findFilters = searchFilters.find(param => param.startsWith('filters'));
    const filters = findFilters ? findFilters.split('=')[1] : '';
    const splitFilters = filters.split(',');
    const finance = splitFilters.some(type => type === 'finance');
    const lease = splitFilters.some(type => type === 'lease');
    const cars = splitFilters.some(cat => cat === 'cars-n-minivan');
    const trucks = splitFilters.some(cat => cat === 'trucks');
    const crossovers = splitFilters.some(cat => cat === 'crossovers-n-suvs');
    const hybrids = splitFilters.some(cat => cat === 'hybrids-n-evs');

    if (splitFilters.length && splitFilters[0] !== '') {
      AppStore.setIsFinanceChecked(finance);
      AppStore.setIsLeaseChecked(lease);
      AppStore.setFinanceType(finance, lease);
      vehicleCategories.forEach(cat => {
        switch (cat.name) {
          case 'cars': {
            cat.selected = cars;
            break;
          }
          case 'trucks': {
            cat.selected = trucks;
            break;
          }
          case 'crossovers': {
            cat.selected = crossovers;
            break;
          }
          case 'hybrids': {
            cat.selected = hybrids;
            break;
          }
        }
      });
      AppStore.categoryFiltersAvailable = vehicleCategories.filter(item => item.selected).length > 0;
      AppStore.setCategories([...vehicleCategories]);
    } else if (!mobile) {
      AppStore.setFinanceType(true, true);
      AppStore.setIsFinanceChecked(true);
      AppStore.setIsLeaseChecked(true);
      vehicleCategories.forEach(cat => (cat.selected = true));
    }
    AppStore.filters = filters;
  },
  domain: 'toyota',
  setDomain: () => {
    const batDomain = document.referrer.split(/[.\-_]/).some(param => param === 'jsds' || param === 'buyatoyota');
    AppStore.domain = batDomain ? 'buyatoyota' : 'toyota';
  },
});

export const AppStoreContext = React.createContext({ AppStore });
export default AppStore;
