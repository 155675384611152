import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import Container from '../../../src/components/Container';
import { AppStoreContext } from '../../../src/stores/AppStore';
import styles from './Header.module.scss';

interface HeaderProps {
  title?: string;
  isMobileWorkflow?: boolean;
  hideBackBtn?: boolean;
  onBack?: () => void;
}

const Header = ({
  hideBackBtn,
  title,
  isMobileWorkflow = false,
  onBack = () => {},
}: HeaderProps) => {

  const [ redirect, setRedirect ] = useState(false);
  const { t } = useTranslation();
  const {
    AppStore: {
      previousMobileState,
      zip,
      language,
      filters
    },
  } = useContext(AppStoreContext);

  const handleBack = () => {
    onBack();
    if (isMobileWorkflow) {
      previousMobileState(); 
    } else {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to={`/?lang=${language}/?zip=${zip}/?filters=${filters}`} />
  }

  return (
    <div className={styles.header}>
      <Container>
        <div className={styles.headerContainer}>
          { !hideBackBtn &&
            <button 
              className={styles.backBtn}
              onClick={() => handleBack()}
            />
          }
          
          <span className={styles.headingLabel}>
            { title !== undefined && title }
            { !title && t(`header.title`)}
          </span> 
        </div>
      </Container>
    </div> 
  );
};

export default observer(Header);
