import { API, createUrlParamsFromObject } from './api';
import { DealersRequest } from './models/dis.models';

export const getDealers = (request: DealersRequest) => {
  const params = createUrlParamsFromObject(request);  
  const disUrl = `${process.env.REACT_APP_DEALERS_API}/disrest/getDealers?${params}&resultsFormat=json`;
  return API({
    method: 'GET',
    url: disUrl,
    headers: { 'x-api-key': process.env.REACT_APP_DIS_API_KEY }
  });
};
