import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './disclaimers.module.scss';
import ModalClose from '../../../src/components/Modal/ModalClose';
import { PostMessageStoreContext } from '../../stores/PostMessageStore';

interface DisclaimersProps {
  onClose: () => void;
  children?: React.ReactNode;
}

const Disclaimers = (props: DisclaimersProps) => {

  const { onClose, children } = props;
  const { t } = useTranslation();
  const {
    postMessageStore: { scrollTop }
  } = React.useContext(PostMessageStoreContext);
  
  useEffect(() => {
    scrollTop();
  },[scrollTop]);

  return (
    <section>
      <ModalClose close={() => onClose()} />
      <h3 className={styles.title}>{t('disclaimers.title')}</h3>
      <section className={styles.disclaimers}>
        <ul className={styles.list}>
          <li>
            <span className={styles.index}>1</span>
            <p className={styles.description}>{children}</p>
          </li>
        </ul>
      </section>
    </section>
  );
};

export default Disclaimers;
