import { API } from './api';
import { EFCSeriesRequest, EFCColorsRequest } from './models/efc.models';

export const getSeries = ({regionCode, years, lang}: EFCSeriesRequest) => {
  const efcSeriesUrl = `${process.env.REACT_APP_EFCMS_API}/v1/TOY/${regionCode}/${lang}/series?year=${years}`;
  return API({
    method: 'GET',
    url: efcSeriesUrl,
    headers: { 'x-api-key': process.env.REACT_APP_EFCMS_API_KEY }
  });
}

export const getTrims = ({regionCode, years, lang}: EFCSeriesRequest) => {
  const efcTrimsUrl = `${process.env.REACT_APP_EFCMS_API}/v2/TOY/${regionCode}/${lang}/trims?year=${years}&attributes=code,description,attributes`;
  return API({
    method: 'GET',
    url: efcTrimsUrl,
    headers: { 'x-api-key': process.env.REACT_APP_EFCMS_API_KEY }
  });
}

export const getColors = ({regionCode, year, series}: EFCColorsRequest) => {
  const efcColorUrl = `${process.env.REACT_APP_EFCMS_API}/v1/TOY/${regionCode}/EN/colors?year=${year}&series=${series}`;
  return API({
    method: 'GET',
    url: efcColorUrl,
    headers: { 'x-api-key': process.env.REACT_APP_EFCMS_API_KEY }
  });
}
