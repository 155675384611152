import moment from 'moment';

import { API } from './api';
import { OATMSMarketingRequest } from './models/oatms.models';
import AppStore from '../stores/AppStore';

export const getMarketingData = ({zipCode, offerId, lang, domain}: OATMSMarketingRequest) => {
  const clientId = domain === 'buyatoyota' ? process.env.REACT_APP_OATMS_BAT_CLIENT_ID : process.env.REACT_APP_OATMS_TCOM_CLIENT_ID;
  const effectiveDate = `&effectiveDate=${moment().format('YYYY-MM-DD')}`;
  const url = `${process.env.REACT_APP_OATMS_API}/v2/marketing/offers/${zipCode}?clientId=${clientId}&lang=${lang}&offerId=${offerId}${effectiveDate}&brandId=${AppStore.brandId}`;
  return API({
    url,
    method: 'GET',
    headers: {'x-api-key': process.env.REACT_APP_OATMS_API_KEY}
  });
}
